import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TicketAPI } from "../../apis/TicketAPI";
import { StatusAPI } from "../../apis/StatusAPI";
import { CompanyAPI } from "../../apis/CompanyAPI";
import { NavLink } from "react-router-dom";
import { displayIndexOrNot } from "./../../utils/indexColor";
import IndexContext from "./../../context/indexContext";
import isLoggedIn from "../../utils/isLoggedIn";
import ReactPaginate from 'react-paginate';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TicketItem from "./TicketItem";
import userContext from "./../../context/userContext";

const AllTickets = () => {
    const navigate = useNavigate();
    const { displayIndex, setDisplayIndex } = useContext(IndexContext)

    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [allTickets, setAllTickets] = useState();
    const [status, setStatus] = useState();
    const [filteredTickets, setFilteredTickets] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [searchStatus, setSearchStatus] = useState("")
    const [orderBy, setOrderBy] = useState("")
    const [remountComponent, setRemountComponent] = useState(0);
    const [displaySearch, setdisplaySearch] = useState(false);
    const [clients, setClients] = useState();
    const [searchClient, setSearchClient] = useState("");

    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [ticketsPaginated, setTicketsPaginated] = useState(0);
    const itemsPerPage = 7;
    const endOffset = pageOffset + itemsPerPage;

    const paginateData = (data) => data.slice(pageOffset, endOffset);

    const searchCheck = (data, search) => data.toLowerCase().includes(search.toLowerCase());

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredTickets.length;
        setTicketsPaginated(filteredTickets.slice(newOffset, newOffset + itemsPerPage));
    };

    const getData = () => {
        Promise.all([TicketAPI.getAllTickets(), StatusAPI.getAllStatus(), CompanyAPI.getAllCompanies()])
            .then(function (results) {
                setFilteredTickets(results[0].data);
                setAllTickets(results[0].data);
                setPageCount(Math.ceil(results[0].data.length / itemsPerPage));
                setTicketsPaginated(paginateData(results[0].data));
                setStatus(results[1].data)
                setClients(results[2].data)


                setDisplayIndex({
                    ...displayIndex,
                    ticket: displayIndexOrNot(results[0].data, "Résolu")
                })
                setIsLoading(false)


            }).catch(function (err) {
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate("/profil");
                } else {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                }

            });
    }

    const filterTickets = () => {

        if (!displaySearch) {
            setdisplaySearch(true);
            return
        }

        // Tous les tickets récupérés de l'API
        let results = allTickets;

        //Si l'utilisateur a spécifié un nom ou prénom pour un auteur de ticket
        if (searchTerm) {
            results = results.filter(ticket => searchCheck(ticket.author.lastname, searchTerm) || searchCheck(ticket.author.firstname, searchTerm));
        }
        //Si l'utilisateur a spécifié un statut de ticket
        if (searchStatus) {
            results = results.filter(ticket => ticket.status !== null);
            results = results.filter(ticket => searchCheck(ticket.status.statusName, searchStatus));
        }
        //Si l'utilisateur a spécifié un client  
        if (searchClient) {
            results = results.filter(ticket => ticket.author.company !== null);
            results = results.filter(ticket => searchCheck(ticket.author.company.companyName, searchClient));
        }
        //Si l'utilisateur a spécifié du plus récent ou plus ancien  
        if (orderBy) {
            if (orderBy === "ASC") {
                results = results.sort(function (a, b) {
                    return a.id - b.id;
                })
            } else if (orderBy === "DESC") {
                results = results.sort(function (a, b) {
                    return b.id - a.id;
                })
            }
        }
        // On stocke les tickets filtrés pour affichage
        setFilteredTickets(results);
        // La Pagination
        setPageCount(Math.ceil(results.length / itemsPerPage));
        setTicketsPaginated(results.slice(0, 0 + itemsPerPage));
        setPageOffset(0);
        setRemountComponent(Math.random());
        // On remet les filtres à 0
        setSearchTerm('');
        setSearchStatus('');
        setSearchClient('');
        setOrderBy('')
    }

    useEffect(
        () => {
            document.title = "Devolie - Mes tickets"

            if (!isLoggedIn()) {
                navigate("/");
            } else {
                getData();
            }
        },
        []
    );

    return (
        <div>
            <div className={`${userLoggedIn.roles[0] === "ROLE_CLIENT" && "header-with-cta"}`}>
                <h1>Les tickets</h1>
                {
                    userLoggedIn.roles[0] === "ROLE_CLIENT" && (
                        <NavLink to="/ticket/ajouter">
                            <button className="btn btn-primary create-item-btn"><AddOutlinedIcon />Ouvrir un ticket</button>
                        </NavLink>
                    )
                }
            </div>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !allTickets && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {!isLoading && allTickets && allTickets.length > 0 && (
                <>
                    <div className="search-container" >

                        {
                            displaySearch && (
                                <div className="search-container-inputs">
                                    {userLoggedIn.roles[0] !== "ROLE_CLIENT" && (
                                        <>
                                            <input name="searchTerm" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder="Prénom ou nom" />

                                            <select value={searchClient} onChange={(e) => { setSearchClient(e.target.value) }}>
                                                <option value="">Client</option>
                                                {
                                                    clients && Object.keys(clients).map((key, i) => <option value={clients[i].companyName} key={clients[i].companyName}>{clients[i].companyName}</option>)
                                                }
                                            </select>
                                        </>

                                    )}
                                    <select value={searchStatus} onChange={(e) => { setSearchStatus(e.target.value) }}>
                                        <option value="">Statut</option>
                                        {
                                            status && Object.keys(status).map((key, i) => <option value={status[i].statusName} key={status[i].statusName}>{status[i].statusName}</option>)
                                        }
                                    </select>

                                    <select value={orderBy} onChange={(e) => { setOrderBy(e.target.value) }}>
                                        <option value="">Date</option>
                                        <option value="ASC">Plus ancien</option>
                                        <option value="DESC">Plus récent</option>

                                    </select>
                                </div>
                            )
                        }

                        <button className="search-btn" onClick={filterTickets}><span className="btn-content"><SearchOutlinedIcon />Rechercher</span></button>

                    </div>

                    {ticketsPaginated.length > 0 && (
                        <div className="table-container">
                            <table className="list-container list-tickets-container">
                                <thead className="list-container--header">
                                    <tr>
                                        <th>Auteur</th>
                                        <th>Ticket</th>
                                        <th>Objet</th>
                                        <th>Statut</th>
                                        <th >Agent</th>
                                        <th>Catégorie</th>
                                        <th>Créé le</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ticketsPaginated.map(ticket => <TicketItem ticket={ticket} key={ticket.id} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!ticketsPaginated.length > 0 && (<p className="no-item-found">Pas de tickets trouvés.</p>)}
                    <div className="pagination" key={remountComponent}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            forcePage={pageOffset}
                        />
                    </div>
                </>

            )}
            {!isLoading && allTickets && allTickets.length === 0 && (
                <p>Pas tickets disponibles.</p>
            )}

        </div>

    );
};

export default AllTickets;