import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { APIFiles } from "../../../utils/api";
import { yupResolver } from "@hookform/resolvers/yup"
import { CompanyAPI } from "../../../apis/CompanyAPI";
import { InvoiceAPI } from "../../../apis/InvoiceAPI";
import { months, threeYearsInterval } from "./../../../utils/date"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const EditInvoice = ({ invoice, setInvoice, setDisplaySuccessModal }) => {
    const schoolDateData = {
        display: false,
        month: invoice.schoolDate ? invoice.schoolDate.slice(0, 2) : "",
        year: invoice.schoolDate ? invoice.schoolDate.slice(-4) : ""
    }

    const uploadsData = {
        fileInput1: {
            show: false,
            file: ""
        },
        fileInput2: {
            show: false,
            file: ""
        },
    }

    const [uploads, setUploads] = useState(uploadsData);
    const [isLoading, setIsLoading] = useState(true)
    const [invoiceToUpdate, setInvoiceToUpdate] = useState(invoice)
    const [clients, setClients] = useState()
    const [apiErr, setApiErr] = useState("")
    const [schoolDate, setSchoolDate] = useState(schoolDateData);

    const yupSchema = yup.object({
        amount: yup.number().typeError("Veuillez renseigner un montant valide.").required("Veuillez remplir ce champ."),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit = async (e) => {

        const formData = new FormData();
        formData.append('client', invoiceToUpdate.client.id);
        formData.append('amount', invoiceToUpdate.amount);
        formData.append('schoolDate', schoolDate.month + "/" + schoolDate.year);
        formData.append('file1', uploads.fileInput1.file);
        formData.append('file2', uploads.fileInput2.file);

        await InvoiceAPI.updateInvoice(formData, invoice.id).then(
            function (res) {
                setInvoice(res.data)
                setUploads(uploadsData)
                setDisplaySuccessModal(true)

                setTimeout(() => {
                    setDisplaySuccessModal(false)
                }, 9000);
            }
        ).catch(function (error) {
            setError('globalError', { type: 'global', message: error.response.data });
        });
    }

    const selectClient = (e) => {
        setInvoiceToUpdate({ ...invoice, client: { id: Number(e.target.value) } });
        let isClient = e.target.options[e.target.selectedIndex].getAttribute('isclient');
        isClient === "true" ? setSchoolDate({ ...schoolDate, display: true }) : setSchoolDate({ ...schoolDate, display: false })
    }

    const getClients = () => {
        setApiErr("");
        CompanyAPI.getAllCompanies().then((response) => {
            setIsLoading(false)
            setClients(response.data)
        })
            .catch(() => {
                setApiErr("Une erreur est survenue");
                setIsLoading(false)
            });
    };

    useEffect(() => {
        getClients()
        if (invoice.client.isSchool) setSchoolDate({ ...schoolDate, display: true })
    }, []);

    return (
        <>

            {
                !isLoading && clients && (
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="select-container form-input">
                            <label>Client*</label>
                            <select onChange={selectClient} value={invoiceToUpdate.client.id} >
                                {
                                    clients.map(client => <option value={client.id} key={client.id + client.companyName} isclient={`${client.isSchool}`}>{client.companyName}</option>)
                                }
                            </select>
                        </div>


                        {schoolDate.display && (
                            <div>
                                <div className="select-container form-input">
                                    <label>Mois*</label>
                                    <select onChange={(e) => setSchoolDate({ ...schoolDate, month: e.target.value })} value={schoolDate.month}>

                                        {
                                            months.map((month, i) => <option value={String(1 + i).padStart(2, '0')} key={i + month}>{month}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="select-container form-input">
                                    <label>Année*</label>
                                    <select onChange={(e) => setSchoolDate({ ...schoolDate, year: e.target.value })} value={schoolDate.year}>

                                        {
                                            threeYearsInterval.map((year, i) => <option value={year} key={i + year}>{year}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        )}


                        <div>
                            <div className="form-input">
                                <label>Montant TTC*</label>
                                <input {...register("amount")} type="text" onChange={(e) => setInvoiceToUpdate({ ...invoiceToUpdate, amount: e.target.value })} value={invoiceToUpdate.amount} />
                            </div>
                            {errors?.amount && (
                                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.amount.message}</div>
                            )}
                        </div>

                        <div className="invoice-files-container">
                            {
                                !uploads.fileInput1.show && (
                                    <div>

                                        <div className="invoice-file-info">
                                            <a href={`${APIFiles}/invoicesFromCollaborator/${invoice.fileName}`} target="_blank" rel="noopener noreferrer" >
                                                <p className="title"><VisibilityOutlinedIcon /> Voir votre facture : </p>
                                                <p>{invoice.fileName}</p>
                                            </a>
                                        </div>
                                        <p className="edit-file-cta" onClick={() => setUploads({
                                            ...uploads, fileInput1: {
                                                show: true
                                            }
                                        })}><EditOutlinedIcon />Modifier le fichier</p>
                                    </div>
                                )
                            }
                            {
                                uploads.fileInput1.show && (
                                    <div>
                                        <div className="form-input">
                                            <label>Facture</label>
                                            <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUploads({ ...uploads, fileInput1: { show: true, file: e.target.files[0] } })} />
                                        </div>
                                        <p className="cancel-edit-file" onClick={() => setUploads({ ...uploads, fileInput1: { show: false, file: "" } })}><CloseOutlinedIcon />Annuler la modification</p>
                                    </div>
                                )
                            }
                            {invoice.uploads.length > 0 && (
                                <div>
                                    {
                                        !uploads.fileInput2.show && (
                                            <div>
                                                <div className="invoice-file-info">
                                                    <a href={`${APIFiles}/invoicesFromCollaborator/${invoice.uploads[0].filename}`} target="_blank" rel="noopener noreferrer" >
                                                        <p className="title"><VisibilityOutlinedIcon /> Voir votre fichier autre : </p>
                                                        <p>{invoice.uploads[0].filename}</p>
                                                    </a>
                                                </div>
                                                <p className="edit-file-cta" onClick={() => setUploads({
                                                    ...uploads, fileInput2: {
                                                        show: true
                                                    }
                                                })}><EditOutlinedIcon />Modifier le fichier</p>
                                            </div>
                                        )
                                    }
                                    {
                                        uploads.fileInput2.show && (

                                            <div>
                                                <div className="form-input">
                                                    <label>Autre fichier</label>
                                                    <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUploads({ ...uploads, fileInput2: { show: true, file: e.target.files[0] } })} />
                                                </div>
                                                <p className="cancel-edit-file" onClick={() => setUploads({ ...uploads, fileInput2: { show: false, file: "" } })}><CloseOutlinedIcon />Annuler la modification</p>

                                            </div>
                                        )
                                    }
                                </div>
                            )}
                        </div>
                        {errors?.globalError && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                        )}
                        <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Modification...")) || ("Modifier la facture")}</button>
                    </form>


                )
            }

        </>

    );
};

export default EditInvoice;