import React, {useEffect} from "react";
import logo from './../../assets/images/logo.png'
import './home.scss';
import LoginForm from './LoginForm'
import isLoggedIn from "./../../utils/isLoggedIn";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Devolie - Connexion"
        if (isLoggedIn()){
            navigate("/profil");
        } else {
            navigate("/");
        }
    }, []);

    return (
        <div className="login-container">
            <div className="desktop-left">
                <div className="desktop-left__iner">
                <div className="logo-container"><img src={logo} alt="Logo"/></div>
                    <div className="message">
                        <p className="message-title">Bonjour et bienvenue</p>
                        <p className="message-content">Vous êtes sur le site de maintenance de l'agence web Devolie. Si vous possédez déjà vos identifiants, vous pouvez vous connecter et créer un ticket pour déclarer une anomalie sur votre site, ou pour toute suggestion d'évolution !</p>
                    </div>
                </div>
            </div>
            <LoginForm/>
        </div>
    );
};

export default Home;