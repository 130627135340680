import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "../../context/userContext";
import "./maintenances.scss"
import AllMaintenances from "./admin/AllMaintenances";
import AllMaintenancesClient from "./client/AllMaintenances";

const MaintenancesPage = () => {
    const navigate = useNavigate();

    const { userLoggedIn } = useContext(userContext);

    useEffect(
        () => {
            document.title = "Devolie - Les maintenances"
            if (userLoggedIn.roles[0] === "ROLE_COLLABORATOR") navigate("/profil")
        },
        []
    );

    return (
        <div>
            {userLoggedIn.roles[0] === "ROLE_ADMIN" && <AllMaintenances/>}
            {userLoggedIn.roles[0] === "ROLE_CLIENT" && <AllMaintenancesClient/>}
        </div>

    );
};

export default MaintenancesPage;