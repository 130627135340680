import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MaintenanceAPI } from "../../../apis/MaintenanceAPI";
import { CompanyAPI } from "../../../apis/CompanyAPI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import userContext from "../../../context/userContext";


const AddMaintenanceContract = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);

    const [apiErr, setApiErr] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState(true);
    const [maintenanceData, setMaintenanceData] = useState({
        dateStart: "",
        client: ""
    });

    const submit = async () => {
        await MaintenanceAPI.addMaintenanceContract(maintenanceData).then(() => {
            navigate('/maintenances')
        })
            .catch(() => {
                setError('globalError', { type: 'global', message: "Une erreur est survenue" });
            });
    };

    const yupSchema = yup.object({
        selectClient: yup.string().required("Veuillez choisir un client."),
        selectDate: yup.string().required("Veuillez choisir une date."),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    useEffect(() => {
        document.title = "Devolie - Ajouter un contrat de maintenance"
        if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");

        CompanyAPI.getAllCompanies().then((response) => {
            setIsLoading(false)
            setCompanies(response.data)
        })
            .catch(() => {
                setApiErr("Une erreur est survenue");
                setIsLoading(false)
            });



    }, []);

    return (
        <div  >
            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            <h1>Ajouter un contrat de maintenance</h1>
            {apiErr && (
                <p className="api-err alert"><ErrorOutlineOutlinedIcon />{apiErr}</p>
            )}
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {!isLoading && companies && (
                <form className="add-contract-form" onSubmit={handleSubmit(submit)}>

                    <div>
                        <div className="select-container form-input">
                            <label>Client*</label>
                            <select  {...register("selectClient")} onChange={(e) => setMaintenanceData({ ...maintenanceData, client: e.target.value })} value={maintenanceData.client}>
                                <option selected={true} value="" disabled>Choisir</option>
                                {
                                    companies.map(company => <option value={company.id} key={company.id + company.companyName} iscompany={`${company.isSchool}`}>{company.companyName}</option>)
                                }
                            </select>

                        </div>
                        {errors?.selectClient && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectClient.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="form-input date-input">
                            <label htmlFor="date">Début de contrat*</label>
                            <div>
                                <input {...register("selectDate")} id="date" type="month" onChange={(e) => setMaintenanceData({ ...maintenanceData, dateStart: e.target.value })} value={maintenanceData.dateStart} />
                            </div>
                        </div>
                        {errors?.selectDate && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectDate.message}</div>
                        )}

                    </div>

                    {errors?.globalError && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                    )}
                    <button disabled={isSubmitting} className="btn btn-primary" type="submit"> {(isSubmitting && ("Ajout en cours...")) || ("Ajouter")}</button>
                </form>

            )}
        </div>
    );
};

export default AddMaintenanceContract;