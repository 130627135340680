import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceAPI } from "../../apis/InvoiceAPI";
import { CompanyAPI } from "../../apis/CompanyAPI";
import { NavLink } from "react-router-dom";
import isLoggedIn from "../../utils/isLoggedIn";
import ReactPaginate from 'react-paginate';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import InvoiceItem from "./InvoiceItem";
import userContext from "./../../context/userContext";
import { displayIndexOrNot } from "./../../utils/indexColor";
import IndexContext from "./../../context/indexContext";

const AllInvoices = () => {
    const navigate = useNavigate();
    const { displayIndex, setDisplayIndex } = useContext(IndexContext)

    const { userLoggedIn } = useContext(userContext);

    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [AllInvoices, setAllInvoices] = useState();
    const [status, setStatus] = useState();
    const [clients, setClients] = useState();
    const [filteredInvoices, setFilteredInvoices] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [searchClient, setSearchClient] = useState("");
    const [searchStatus, setSearchStatus] = useState("")
    const [orderBy, setOrderBy] = useState("")
    const [remountComponent, setRemountComponent] = useState(0);
    const [displaySearch, setdisplaySearch] = useState(false);

    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [invoicesPaginated, setInvoicesPaginated] = useState(0);
    const itemsPerPage = 6;
    const endOffset = pageOffset + itemsPerPage;

    const paginateData = (data) => data.slice(pageOffset, endOffset);

    const searchCheck = (data, search) => data.toLowerCase().includes(search.toLowerCase());

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredInvoices.length;
        setInvoicesPaginated(filteredInvoices.slice(newOffset, newOffset + itemsPerPage));
    };

    const filterInvoices = () => {

        if (!displaySearch) {
            setdisplaySearch(true);
            return
        }

        let results = AllInvoices;
        if (searchTerm) {
            results = results.filter(invoice => searchCheck(invoice.collaborator.firstname, searchTerm) || searchCheck(invoice.collaborator.lastname, searchTerm));
        }
        if (searchStatus) {
            results = results.filter(invoice => invoice.status !== null);
            results = results.filter(invoice => searchCheck(invoice.status.statusName, searchStatus));
        }
        if (searchClient) {
            results = results.filter(invoice => searchCheck(invoice.client.companyName, searchClient));
        }
        if (orderBy) {
            if (orderBy === "ASC") {
                results = results.sort(function (a, b) {
                    return a.id - b.id;
                })
            } else if (orderBy === "DESC") {
                results = results.sort(function (a, b) {
                    return b.id - a.id;
                })
            }
        }
        setFilteredInvoices(results);
        setPageCount(Math.ceil(results.length / itemsPerPage));
        setInvoicesPaginated(results.slice(0, 0 + itemsPerPage));
        setPageOffset(0);
        setRemountComponent(Math.random());
        setSearchTerm('');
        setSearchStatus('');
        setOrderBy('')
    }

    useEffect(
        () => {
            document.title = "Devolie - Mes factures"

            if (!isLoggedIn() && userLoggedIn.roles[0] !== "ROLE_CLIENT") {
                navigate("/");
            } else {
                Promise.all([InvoiceAPI.getAllInvoices(), InvoiceAPI.getAllStatus(), CompanyAPI.getAllCompanies()])
                    .then(function (results) {
                        console.log(results[0].data)

                        setAllInvoices(results[0].data)
                        setFilteredInvoices(results[0].data);
                        setPageCount(Math.ceil(results[0].data.length / itemsPerPage));
                        setInvoicesPaginated(paginateData(results[0].data));
                        setStatus(results[1].data)
                        setClients(results[2].data)
                        setDisplayIndex({
                            ...displayIndex,
                            invoice: displayIndexOrNot(results[0].data, "Réglée")
                        })
                        setIsLoading(false)

                    }).catch(function (err) {
                        if (err.response.status === 401 || err.response.status === 403) {
                            navigate("/profil");
                        } else {
                            setIsLoading(false)
                            setApiErr("Une erreur est survenue")
                        }

                    });
            }
        },
        []
    );

    return (
        <div>
            <div className={`${userLoggedIn.roles[0] === "ROLE_COLLABORATOR" && "header-with-cta"}`}>
                <h1>Mes factures</h1>
                {
                    userLoggedIn.roles[0] === "ROLE_COLLABORATOR" && (
                        <NavLink to="/facture/ajouter">
                            <button className="btn btn-primary create-item-btn"><AddOutlinedIcon />Déposer une facture</button>
                        </NavLink>
                    )
                }
            </div>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !AllInvoices && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {!isLoading && AllInvoices && AllInvoices.length > 0 && (
                <>
                    <div className="search-container" >

                        {
                            displaySearch && (
                                <div className="search-container-inputs">

                                    {userLoggedIn.roles[0] === "ROLE_ADMIN" && (<input name="searchTerm" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder=" Prénom ou nom" />)}


                                    <select value={searchClient} onChange={(e) => { setSearchClient(e.target.value) }}>
                                        <option value="">Client</option>
                                        {
                                            clients && Object.keys(clients).map((key, i) => <option value={clients[i].companyName} key={clients[i].companyName}>{clients[i].companyName}</option>)
                                        }
                                    </select>

                                    <select value={searchStatus} onChange={(e) => { setSearchStatus(e.target.value) }}>
                                        <option value="">Statut</option>
                                        {
                                            status && Object.keys(status).map((key, i) => <option value={status[i].statusName} key={status[i].statusName}>{status[i].statusName}</option>)
                                        }
                                    </select>

                                    <select value={orderBy} onChange={(e) => { setOrderBy(e.target.value) }}>
                                        <option value="">Date</option>
                                        <option value="ASC">Plus ancien</option>
                                        <option value="DESC">Plus récent</option>

                                    </select>
                                </div>
                            )
                        }

                        <button className="search-btn" onClick={filterInvoices}><span className="btn-content"><SearchOutlinedIcon />Rechercher</span></button>

                    </div>
                    {invoicesPaginated.length > 0 && (
                        <div className="table-container">
                            <table className="list-container list-invoices-collaborator-container">
                                <thead className="list-container--header">
                                    <tr>
                                        {userLoggedIn.roles[0] === "ROLE_ADMIN" && (<th>Collaborateur</th>)}
                                        <th>N° de facture</th>
                                        <th>Client</th>
                                        <th>Date de dépôt</th>
                                        <th>Statut</th>
                                        <th >Montant TTC</th>
                                        <th>Date d'intervention</th>
                                    </tr>
                                </thead>
                                <tbody>
                                     {
                                        invoicesPaginated.map(invoice => <InvoiceItem invoice={invoice} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!invoicesPaginated.length > 0 && (<p className="no-item-found">Pas de factures trouvées.</p>)}
                    <div className="pagination" key={remountComponent}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            forcePage={pageOffset}
                        />
                    </div>
                </>
            )}
            {!isLoading && AllInvoices && AllInvoices.length === 0 && (
                <p>Pas de factures disponibles.</p>
            )}
        </div>

    );
};

export default AllInvoices;