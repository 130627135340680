import React, { useState, useEffect } from "react";
import { APIFiles } from "../../../utils/api";
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import { InvoiceAPI } from "./../../../apis/InvoiceAPI";
import DeleteInvoice from "./DeleteInvoice";

const AdminEdit = ({ invoice, setInvoice, setDisplaySuccessModal }) => {
    const navigate = useNavigate();

    const [status, setStatus] = useState();
    const [newStatus, setNewStatus] = useState(invoice.status ? invoice.status.id : "");
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState()

    const updateStatus = async () => {
        setIsSubmitting(true)
        await InvoiceAPI.updateStatus(newStatus, invoice.id).then(
            function (res) {
                setInvoice(res.data)
                setDisplaySuccessModal(true)
                setIsSubmitting(false)

                setTimeout(() => {
                    setDisplaySuccessModal(false)
                }, 9000);


            }
        ).catch(function (error) {
            setApiErr("Une erreur est survenue")
            setIsSubmitting(false)
        });
    }

    useEffect(() => {
        InvoiceAPI.getAllStatus().then((res) => {
            setStatus(res.data)
            setIsLoading(false)
        })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate("/tickets");
                } else {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                }

            });
    }, []);

    return (
        <>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !status && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {!isLoading && status && (
                <div className="form-add form-edit-admin--invoice">
                    <div className="invoice-files-container">
                        <p className="invoice-download"><a href={`${APIFiles}/invoicesFromCollaborator/${invoice.fileName}`} target="_blank" rel="noopener noreferrer" download><DownloadIcon />La facture</a></p>
                        {invoice.uploads?.length > 0 && (
                            <p className="invoice-download"><a href={`${APIFiles}/invoicesFromCollaborator/${invoice.uploads[0].filename}`} rel="noopener noreferrer" target="_blank" download ><DownloadIcon />Fichier autre</a></p>
                        )}
                    </div>
                    <div className="invoice-details">
                        <p>Collaborateur : {invoice.collaborator.firstname}</p>
                        <p>Date de dépôt : {new Date(invoice.createdAt).toLocaleDateString()}</p>
                        <p>Client : {invoice.client ? invoice.client.companyName : ""}</p>
                        <p>Date d'intervention :  {invoice.schoolDate ? invoice.schoolDate : "Non concerné"}</p>
                        <p>Montant TTC : {invoice.amount}€</p>
                    </div>

                    <div className="select-container form-input">
                        <label>Statut</label>
                        <select onChange={(e) => setNewStatus(e.target.value)} value={newStatus} >
                            <option value="" disabled>Choisir</option>
                            {
                                status.map(status => <option value={status.id} key={status.id + status.statusName}>{status.statusName}</option>)
                            }
                        </select>
                    </div>



                    <div className="btn-container btn-container--left">
                        <button disabled={isSubmitting} className="btn btn-primary create-btn" onClick={updateStatus}>{(isSubmitting && ("Modification...")) || ("Modifier le statut")}</button>
                        <DeleteInvoice invoice={invoice} />
                    </div>


                </div >
            )}


        </>

    );
};

export default AdminEdit;