import React from "react";
import { useNavigate } from "react-router-dom";
import {sliceString} from "./../../utils/formatData"


function TicketItem({ ticket }) {
    const navigate = useNavigate();

    const ticketBg = {
        backgroundColor : ticket.status?.color,
        opacity: ticket.status?.color?  0.6 : 1,
    }

    const handleClick = (e) => {
        let ticketID = e.target.closest('tr').getAttribute("ticketid");

        navigate("/ticket/" + ticketID)
    }

    return (

        <tr className="list-item ticket-item" ticketid={ticket.id} onClick={handleClick}  >
            <td style={ticketBg} className="author-infos">
                <div className="avatar-container">
                    <img src={require('./../../assets/images/avatars/' + ticket.author.avatar.filename)} alt="Avatar" />
                </div>
                <p>{ticket.author.firstname}</p>
            </td>
            <td style={ticketBg}>{String(ticket.id).padStart(3, '0')}</td>
            <td style={ticketBg}><span className="priority-label" style={{ backgroundColor: `${ticket.priority? ticket.priority.color : ""}` }}>{ticket.priority? ticket.priority.priorityName : ""}</span> {sliceString(ticket.subject)} </td>
            <td style={ticketBg}>{ticket.status?.statusName ? ticket.status.statusName : ""}</td>
            <td style={ticketBg}>{ticket.assigned?.firstname ? ticket.assigned.firstname : ""}</td>
            <td style={ticketBg}>{ticket.category? ticket.category.categoryName : ""}</td>
            <td style={ticketBg}>{new Date(ticket.createdAt).toLocaleDateString()}</td>
        </tr>

    )
}

export default TicketItem;