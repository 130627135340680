import React, { useState } from "react";
import { InvoiceAPI } from "./../../../apis/InvoiceAPI";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function StatusItem({ status, setAllStatus }) {
    const [displayEditForm, setDisplayEditForm] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false)

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [statusToUpdate, setStatusToUpdate] = useState({
        statusName: status.statusName,
        color: status.color ? status.color : "#FFFFFFF"
    })

    const editStatus = () => {
        setIsSubmitting(true)

        if (statusToUpdate.statusName === "") {
            setApiErr("Veullez spécifier le statut.");
            return
        }

        InvoiceAPI.editStatus(statusToUpdate, status.id).then((response) => {
            setAllStatus(response.data)
            setDisplayEditForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    const deleteStatus = async () => {
        setIsSubmitting(true)
        await InvoiceAPI.deleteStatus(status.id).then(
            function (response) {
                setAllStatus(response.data)
                setIsSubmitting(false)
                setDisplayDeleteModal(false)

            }
        ).catch(function () {
            setApiErr("Une erreur est survenue");
            setIsSubmitting(false)
        });
    }

    return (
        <>
            {!displayEditForm && (
                <li className="list-item" key={status.id}>
                    <div>

                        <p>{status.statusName}</p>
                        {
                            status.color && (
                                <div className="status-color" style={{ backgroundColor: `${status.color}` }}></div>
                            )
                        }
                    </div>

                    <div>
                        <button className="btn btn-primary" onClick={() => { setDisplayEditForm(true) }}>Modifier</button>
                        <button onClick={() => { setDisplayDeleteModal(true) }} className="btn btn-delete">Supprimer</button>
                    </div>
                </li>
            )}
            {
                displayDeleteModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDisplayDeleteModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer cette catégorie ?</p>
                        <p>{status.statusName}</p>
                        <button onClick={deleteStatus} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }
            {displayEditForm && (
                <li className="edit-item">
                    <div className="edit-form">
                        <div className="company-input">
                            <input type="text" onChange={(e) => setStatusToUpdate({ ...statusToUpdate, statusName: e.target.value })} value={statusToUpdate.statusName} />
                        </div>

                        <input type="color" onChange={(e) => setStatusToUpdate({ ...statusToUpdate, color: e.target.value })} value={statusToUpdate.color} />
                    </div>

                    <div className="btn-container">
                        <button disabled={isSubmitting} className="btn btn-primary" onClick={editStatus}>{(isSubmitting && ("En cours...")) || ("Valider")}</button>
                        <button className="btn btn-delete" onClick={() => setDisplayEditForm(false)}>Annuler</button>
                    </div>
                </li>
            )}
        </>
    )
}

export default StatusItem;