import React from "react";
import { APIFiles } from "./..//../../utils/api";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { months } from "./../../../utils/date"


function LinkMaintenance({ file, url }) {
    return <a href={`${APIFiles}/${url}/${file}`} target="_blank" rel="noopener noreferrer" download><AttachFileOutlinedIcon />Le document</a>
}

function MaintenanceItem({ maintenance }) {
    return (
        <div key={maintenance.id}>
            <div className={`maintenance-item  ${maintenance.isDone && "is-done"}`}>
                <div className="date">
                    <p className="month">{months[parseInt(maintenance.month - 1)].slice(0, 3)}</p>
                    <p>{maintenance.year}</p>
                </div>
                <hr />

                <div>
                    <p className="label">Compte Rendu</p>
                    <p>{maintenance.report ? <LinkMaintenance file={maintenance.report} url="monthlyReports" /> : "Non disponible"}</p>
                </div>
                <hr />

                <div>
                    <p className="label">Facture</p>
                    <p>{maintenance.invoice ? <LinkMaintenance file={maintenance.invoice} url="invoicesForClient" /> : "Non disponible"}</p>
                </div>
                <hr />

                <div>
                    <p className="label">Temps passé</p>
                    <p>{maintenance.timeSpent ? maintenance.timeSpent : "Non disponible"}</p>
                </div>
                <hr />

                <div className="checkbox">
                    <input type="checkbox" checked={maintenance.isDone} />
                    <label>Réglée</label>
                </div>

            </div>
        </div>
    )
}

export default MaintenanceItem;