import React, { useState } from "react";
import { PriorityAPI } from "./../../../apis/PriorityAPI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';



const AddPriorityForm = ({ setAllPriorities, setDisplayAddForm }) => {

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [priorityData, setPriorityData] = useState({
        priorityName: "",
        color: "#FFFFFF"
    });

    const addPriority = () => {
        setIsSubmitting(true)
        if (priorityData.priorityName === "") {
            setApiErr("Veullez spécifier le nom d'entreprise.");
            return
        }
        PriorityAPI.addPriority(priorityData).then((response) => {
            setAllPriorities(response.data)
            setDisplayAddForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    return (
        <div>
            <div className="modal modal-add">
                <div className="close-icon" onClick={() => setDisplayAddForm(false)}><CloseOutlinedIcon /></div>
                <label>Priorité</label>
                <div>
                    <input type="text" onChange={(e) => setPriorityData({ ...priorityData, priorityName: e.target.value })} value={priorityData.priorityName} />
                </div>
                <div className="form-input">
                    <input type="color" onChange={(e) => setPriorityData({ ...priorityData, color: e.target.value })} value={priorityData.color} />
                </div>

                {apiErr && (
                    <div className="alert"><ErrorOutlineOutlinedIcon />{apiErr}</div>
                )}
                <button disabled={isSubmitting} className="btn btn-primary create-item-btn" onClick={() => { addPriority() }}>{(isSubmitting && ("Ajout...")) || ("Ajouter")}</button>

            </div>
        </div>
    );
};

export default AddPriorityForm;