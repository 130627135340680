import "./chat.scss"
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { TicketAPI } from "./../../../apis/TicketAPI"
import MessagesContainer from "./MessagesContainer";
import DeleteTicket from "./DeleteTicket";
import UpdateTicket from "./UpdateTicket";
import SendBox from "./SendBox";
import userContext from "./../../../context/userContext";

const TicketChat = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);

    const [isLoading, setIsLoading] = useState(true)
    const [ticket, setTicket] = useState()
    const [apiErr, setApiErr] = useState()
    const { id } = useParams();

    const getTicketById = () => {
        TicketAPI.getTicketById(id).then((response) => {
            setTicket(response.data);
            setIsLoading(false)
        })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate("/tickets");
                } else {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                }
            });
    };


    useEffect(
        () => {
            getTicketById(id);
            document.title = "Devolie - Mon ticket";
        },
        []
    );
    return (
        <div>
            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !ticket && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {
                !isLoading && ticket && (
                    <div>

                        <div className="ticket-tchat-header">
                            <p>{ticket.author.company ? ticket.author.company.companyName : ""}</p>
                            <p>
                                <span className="priority-label" style={{  backgroundColor: `${ticket.priority? ticket.priority.color : ""}` }}>{ticket.priority? ticket.priority.priorityName : ""}</span>
                                {ticket.subject}
                            </p>
                            <p>{ticket.category? ticket.category.categoryName : ""}</p>
                            <p>{new Date(ticket.createdAt).toLocaleDateString()}</p>
                            <p>{ticket.status?.statusName ? ticket.status.statusName : ""}</p>
                        </div>
                        <div className="window-chat">
                            <MessagesContainer ticket={ticket} />
                            <SendBox ticket={ticket} getTicket={getTicketById} />
                        </div >
                        {userLoggedIn.roles[0] === "ROLE_ADMIN" && (
                            <div className="btn-container btn-container--left">
                                <UpdateTicket ticket={ticket} setTicket={setTicket} />
                                <DeleteTicket ticket={ticket} />
                            </div>
                        )
                        }
                    </div>
                )
            }
        </div >
    )
}

export default TicketChat;