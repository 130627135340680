import React, { useState } from "react";
import { CategoryAPI } from "./../../../apis/CategoryAPI";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function CategoryItem({ category, setAllCategories }) {
    const [displayEditForm, setDisplayEditForm] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false)

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [categoryToUpdate, setCategoryToUpdate] = useState({
        categoryName: category.categoryName,
    })

    const editCategory = () => {
        setIsSubmitting(true)

        if (category.categoryName === "") {
            setApiErr("Veullez spécifier la catégorie.");
            return
        }

        CategoryAPI.editCategory(categoryToUpdate, category.id).then((response) => {
            console.log(response)
            setAllCategories(response.data)
            setDisplayEditForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    const deleteCategory = async () => {
        setIsSubmitting(true)
        await CategoryAPI.deleteCategory(category.id).then(
            function (response) {
                setAllCategories(response.data)
                setIsSubmitting(false)
                setDisplayDeleteModal(false)

            }
        ).catch(function () {
            setApiErr("Une erreur est survenue");
            setIsSubmitting(false)
        });
    }

    return (
        <>
            {!displayEditForm && (
                <li className="list-item" key={category.id}>

                    <p>{category.categoryName} </p>

                    <div>
                        <button className="btn btn-primary" onClick={() => { setDisplayEditForm(true) }}>Modifier</button>
                        <button onClick={() => { setDisplayDeleteModal(true) }} className="btn btn-delete">Supprimer</button>
                    </div>
                </li>
            )}
            {
                displayDeleteModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDisplayDeleteModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer cette catégorie ?</p>
                        <p>{category.categoryName}</p>
                        <button onClick={deleteCategory} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }
            {displayEditForm && (
                <li className="edit-item">
                    <div className="edit-form">
                        <div className="company-input">
                            <input type="text" onChange={(e) => setCategoryToUpdate({ ...categoryToUpdate, categoryName: e.target.value })} value={categoryToUpdate.categoryName} />
                        </div>


                    </div>

                    <div className="btn-container">
                        <button disabled={isSubmitting} className="btn btn-primary" onClick={editCategory}>{(isSubmitting && ("En cours...")) || ("Valider")}</button>
                        <button className="btn btn-delete" onClick={() => setDisplayEditForm(false)}>Annuler</button>
                    </div>
                </li>
            )}
        </>
    )
}

export default CategoryItem;