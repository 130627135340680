import React, { useState } from "react";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { UserAPI } from "../../../apis/UserAPI";

const PasswordForm = () => {
    const [successMessage, setsuccessMessage] = useState();
    const [passwordShown, setPasswordShown] = useState({
        1: false,
        2: false,
        3: false
    });

    const togglePassword = (index) => {
        setPasswordShown({ ...passwordShown, [index]: !passwordShown[index] });
    };

    const yupSchema = yup.object({
        oldPassword: yup.string().required("Veuillez remplir ce champ."),
        newPassword: yup.string().required("Veuillez remplir ce champ.").matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$", "Votre mot de passe doit faire au moins 8 caractères de long, contenir une majuscule et un chiffre."),
        confirmPassword: yup.string().required("Veuillez remplir ce champ.").oneOf(
            [yup.ref('newPassword'), ''],
            'Les mots de passe ne correspondent pas'
        ),
    });

    const { register, handleSubmit, getValues, formState: { errors, isSubmitting }, reset, setError, clearErrors } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit = async () => {
        await UserAPI.editPassword(getValues()).then(
            function (response) {
                if (response.status === 200) {
                    reset();
                    clearErrors();
                    setsuccessMessage(response.data)
                }
            }
        ).catch(function (error) {
            if (error.response.status === 401) {
                setError('globalError', { type: 'global', message: "Vos identifiants sont invalides" });
            } else {
                setError('globalError', { type: 'global', message: "Une erreur est survenue" });
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(submit)}>

            <div className="password-input">
                <input {...register("oldPassword")} type={passwordShown[1] ? "text" : "password"} placeholder="Mot de passe actuel" />
                <div className="show-icon" onClick={() => togglePassword(1)}><RemoveRedEyeOutlinedIcon /></div>
            </div>
            {errors?.oldPassword && (
                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.oldPassword.message}</div>
            )}
            <div className="password-input">
                <input {...register("newPassword")} type={passwordShown[2] ? "text" : "password"} placeholder="Nouveau mot de passe" />
                <div className="show-icon" onClick={() => togglePassword(2)}><RemoveRedEyeOutlinedIcon /></div>
            </div>
            {errors?.newPassword && (
                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.newPassword.message}</div>
            )}
            <div className="password-input">
                <input {...register("confirmPassword")} type={passwordShown[3] ? "text" : "password"} placeholder="Confirmation du mot de passe" />
                <div className="show-icon" onClick={() => togglePassword(3)}><RemoveRedEyeOutlinedIcon /></div>
            </div>
            {errors?.confirmPassword && (
                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.confirmPassword.message}</div>
            )}
            {errors?.globalError && (
                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
            )}

            {successMessage && (
                <div className="alert">{successMessage}</div>
            )}
            <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Changement en cours...")) || ("Changer le mot de passe")}</button>
        </form>
    );
};

export default PasswordForm;