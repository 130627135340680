import React, { useEffect, useState, useContext } from "react";
import { MaintenanceAPI } from "./../../../apis/MaintenanceAPI";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import { months, threeYearsInterval } from "./../../../utils/date"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { orderByDate } from "./../../../utils/date"

const AddMaintenanceForm = ({ contractId, setMaintenances, setDisplayForm }) => {

    const emptyMaintenance = {
        year: "",
        month: "",
        report: "",
        invoice: "",
        timeSpent: "",
    }

    const [newMaintenance, setNewMaintenance] = useState(emptyMaintenance)

    const yupSchema = yup.object({
        month: yup.string().required("Veuillez spécifier le mois."),
        year: yup.string().required("Veuillez spécifier l'année."),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit = async () => {
        const formData = new FormData();

        formData.append('contract', contractId);
        formData.append('year', newMaintenance.year);
        formData.append('month', newMaintenance.month);
        formData.append('timeSpent', newMaintenance.timeSpent);
        formData.append('report', newMaintenance.report);
        formData.append('invoice', newMaintenance.invoice);

        await MaintenanceAPI.addMaintenance(formData).then(
            function (res) {
                let orderedMaintenances = orderByDate(res.data)
                setMaintenances(orderedMaintenances);
                setDisplayForm(false)
            }
        ).catch(function () {
            setError('globalError', { type: 'global', message: "Une erreur est survenue" });
        });
    }

    return (
        <div className="add-maintenance-form">
            <form onSubmit={handleSubmit(submit)}>
                <div>
                    <select  {...register("month")} onChange={(e) => setNewMaintenance({ ...newMaintenance, month: e.target.value })} value={newMaintenance.month}>
                        <option selected={true} value="" disabled>Mois*</option>
                        {
                            months.map((month, i) => <option value={String(1 + i).padStart(2, '0')} key={i + month}>{month}</option>)
                        }
                    </select>
                </div>
                <div>
                    <select  {...register("year")} onChange={(e) => setNewMaintenance({ ...newMaintenance, year: e.target.value })} value={newMaintenance.year}>
                        <option selected={true} value="" disabled>Année*</option>
                        {
                            threeYearsInterval.map((year, i) => <option value={year} key={i + year}>{year}</option>)
                        }
                    </select>
                </div>
                {errors?.date && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.date.message}</div>
                        )}
                <div>
                    <div className="file-input">
                        <label>Compte rendu</label>
                        <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setNewMaintenance({ ...newMaintenance, report: e.target.files[0] })} />
                    </div>
                    <div className="file-input">
                        <label>Facture</label>
                        <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setNewMaintenance({ ...newMaintenance, invoice: e.target.files[0] })} />
                    </div>
                    <div className="upload-info">
                        <p>Taille de fichier maximale: 4MB</p>
                        <p>Formats acceptés : png, jpg, pdf, doc, ppt, xls </p>
                    </div>

                </div>

                <div className="time-spent">
                    <input type="text" onChange={(e) => setNewMaintenance({ ...newMaintenance, timeSpent: e.target.value })} placeholder="Temps passé" value={newMaintenance.timeSpent} />
                </div>

                {errors?.globalError && (
                    <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                )}

                <button disabled={isSubmitting} className="btn btn-primary" type="submit"> {(isSubmitting && ("En cours...")) || ("Ajouter")}</button>
            </form>
        </div>

    );
};

export default AddMaintenanceForm;