import React, { useState, useContext } from "react";
import { UserAPI } from "../apis/UserAPI";
import userContext from "../context/userContext";

const AvatarForm = ({ userData }) => {
    const { userLoggedIn, setUserLoggedIn } = useContext(userContext);
    const [user, setUser] = useState(userData);
    const [newAvatar, setNewAvatar] = useState(userData.avatar.filename);
    const [displayModal, setDisplayModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const avatars = ["cochon.png", "cerf.png", "koala.png", "lapin.png", "loup_blanc.png", "loup.png", "ours_blanc.png", "ours.png", "panda.png", "pingouin.png", "renard.png", "rennes.png", "zebre.png"];

    const editAvatar = async () => {
        setIsSubmitting(true)
        await UserAPI.editAvatar(user.id, newAvatar).then(
            function (response) {
                if (response.status === 200) {
                    setUser(response.data)
                    setIsSubmitting(false)
                    setDisplayModal(false)

                    if (userLoggedIn.id === user.id) {
                        setUserLoggedIn(response.data)
                    }

                }
            }
        ).catch(function (error) {
            console.log(error)
            setIsSubmitting(false)
        });
    }

    const cancelEditAvatar = () => {
        setDisplayModal(false);
        setNewAvatar(user.avatar.filename)
    }

    return (
        <>
            <section>
                <div className="current-avatar" onClick={() => setDisplayModal(true)}>
                    <p>Avatar</p>
                    <div className="avatar-img"><img src={require('./../assets/images/avatars/' + newAvatar)} alt="Avatar" /></div>
                </div>
                {displayModal && (
                    <div className="avatar-modal ">
                        <div className="close-modal" onClick={cancelEditAvatar}>X</div>
                        <div className="avatars-container">
                            {avatars.map(avatar => {
                                return <div className={`avatar-img ${avatar === newAvatar ? "selected" : ""} `} key={avatar} onClick={() => setNewAvatar(avatar)}>
                                    <img src={require('./../assets/images/avatars/' + avatar)} alt="Avatar" />
                                </div>
                            })}
                        </div>
                        <button disabled={isSubmitting} className="btn btn-primary" onClick={editAvatar}>{(isSubmitting && ("Modification...")) || ("Modifier mon avatar")}</button>
                    </div>
                )}
            </section>
        </>
    );
};

export default AvatarForm;