import React, { useState } from "react";
import { TicketAPI } from "./../../../apis/TicketAPI";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Player } from '@lottiefiles/react-lottie-player';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const SendBox = ({ ticket, getTicket }) => {
    const uploadsData = {
        fileInput1: {
            show: false,
            file: ""
        },
        fileInput2: {
            show: false,
            file: ""
        },
        fileInput3: {
            show: false,
            file: ""
        },
        fileInput4: {
            show: false,
            file: ""
        },
        inputShown: 0
    }

    const showFileInput = () => {
        switch (uploads.inputShown) {
            case 0:
                setUploads({ ...uploads, fileInput1: { show: true }, inputShown: 1 });
                break;
            case 1:
                setUploads({ ...uploads, fileInput2: { show: true }, inputShown: 2 })
                break;
            case 2:
                setUploads({ ...uploads, fileInput3: { show: true }, inputShown: 3 })
                break;
            case 3:
                setUploads({ ...uploads, fileInput4: { show: true }, inputShown: 4 });
                setShowAddFiles(false)
                break;
            default:
                console.log("Erreur affichage input");
        }
    }

    const [uploads, setUploads] = useState(uploadsData);
    const [uploadsErr, setUploadsErr] = useState();
    const [showAddFiles, setShowAddFiles] = useState(true);
    const [newMessageContent, setNewMessageContent] = useState("")
    const [apiErr, setApiErr] = useState("")
    const [isSending, setIsSending] = useState("")

    const submit = async () => {

        if(!newMessageContent){
            setApiErr('Veuillez rédiger votre message')
            return;
        }
        setApiErr('');
        setIsSending(true);

        const formData = new FormData();
        formData.append('file1', uploads.fileInput1.file);
        formData.append('file2', uploads.fileInput2.file);
        formData.append('file3', uploads.fileInput3.file);
        formData.append('file4', uploads.fileInput4.file);

        formData.append('messageContent', newMessageContent);
        formData.append('ticketId', ticket.id);

        await TicketAPI.addMessage(formData).then(
            function (res) {
                setIsSending(false)
                setNewMessageContent("")
                setUploads(uploadsData)
                getTicket()
            }
        ).catch(function (error) {
            setIsSending(false)
            console.log(error)
            if (Array.isArray(error.response.data)) {
                setApiErr("Veuillez joindre des fichiers aux formats et taille demandés.");
            } else {
                setApiErr("Une erreur est survenue");
            }
        });
    }

    return (
        <div className="send-form">

            <div className="text-input">
                <textarea placeholder="Votre Message" onChange={(e) => setNewMessageContent(e.target.value)} value={newMessageContent}></textarea>
                {!isSending && <button onClick={submit} className="send-btn"><SendOutlinedIcon /></button>}
                {isSending && (<Player
                    autoplay
                    loop
                    speed="1"
                    src="https://assets5.lottiefiles.com/packages/lf20_dkz94xcg.json"
                    style={{ height: '100px', width: '100px', position: "absolute", right: "1rem", top: "-0.5rem" }}
                >
                </Player>)}
            </div>
            {apiErr && (
                <p className="api-err alert"><ErrorOutlineOutlinedIcon />{apiErr}</p>
            )}
            <div className="uploads-container">
                {uploads.fileInput1.show && (<input type="file" onChange={(e) => uploads.fileInput1.file = e.target.files[0]} />)}
                {uploads.fileInput2.show && (<input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => uploads.fileInput2.file = e.target.files[0]} />)}
                {uploads.fileInput3.show && (<input type="file" accept="image/png, image/jpeg, .pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => uploads.fileInput3.file = e.target.files[0]} />)}
                {uploads.fileInput4.show && (<input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => uploads.fileInput4.file = e.target.files[0]} />)}
                {uploads.fileInput1.show && (
                    <div className="upload-info">
                        <p>Taille de fichier maximale: 4MB</p>
                        <p>Formats acceptés : png, jpg, pdf, doc, ppt, xls </p>
                    </div>
                )}
            </div>

            {showAddFiles && (<div className="add-file" onClick={showFileInput}><AddOutlinedIcon />
                {(uploads.fileInput1.show && `Joindre un autre fichier (${4 - uploads.inputShown} restant(s))`) || "Joindre un fichier (4 max)"}</div>)}
        </div>
    );
};

export default SendBox;