import { createContext } from "react";

const UserContext = createContext({
    userLoggedIn: {
        email: "",
        firstname: "",
        lastname: "",
        roles: "",
        avatar:{
            filename: ""
        }
    },
    setUserLoggedIn: (userData) => {}
});

export default UserContext;