import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MaintenanceAPI } from "./../../../apis/MaintenanceAPI";
import userContext from "./../../../context/userContext";
import { orderByDate } from "../../../utils/date"
import MaintenanceItem from "./MaintenanceItem";

const AllMaintenances = () => {
    const navigate = useNavigate();

    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [maintenances, setMaintenances] = useState();
    const [contracts, setContracts] = useState();

    const messagesEndRef = useRef(null);

    const getContracts = () => {
        MaintenanceAPI.getCompanyContracts().then((response) => {
            setContracts(response.data)
            setIsLoading(false)
        })
            .catch(() => {
                setApiErr("Une erreur est survenue")
            });
    };

    const displayMaintenances = (e) => {
        let foundContract = contracts.find(el => el.id == e.target.value);
        setMaintenances(orderByDate(foundContract.maintenances));
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView();
        }, 100);

    };


    useEffect(
        () => {
            document.title = "Devolie - Mes maintenances"
            if (userLoggedIn.roles[0] !== "ROLE_CLIENT") navigate("/profil");
            getContracts();
        },
        []
    );

    return (
        <div>

            <h1>Mes maintenances</h1>

            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !maintenances && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {!isLoading && contracts && (

                <div className="maintenances-header-select">
                    <select onChange={displayMaintenances} >
                        <option selected={true} value="" disabled>Contrat</option>
                        {
                            contracts.map(contract => <option value={contract.id} key={contract.id + contract.dateStart} >Contrat {new Date(contract.dateStart).getMonth() + 1 + "/" + new Date(contract.dateStart).getFullYear()}</option>)
                        }
                    </select>
                </div>

            )}
            <div className="maintenances-container">
                {maintenances && (
                    <div className="maintenance-list">
                        {maintenances.map((maintenance) => <MaintenanceItem maintenance={maintenance} setMaintenances={setMaintenances} />)}

                    </div>

                )}
                <div ref={messagesEndRef} />
                {!isLoading && contracts.length == 0 && (
                    <p>Vous n'avez pas de contrat de maintenance.</p>
                )}

            </div>
        </div>

    );
};

export default AllMaintenances;