import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MaintenanceAPI } from "./../../../apis/MaintenanceAPI";
import { NavLink } from "react-router-dom";
import isLoggedIn from "./../../../utils/isLoggedIn";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import userContext from "./../../../context/userContext";
import MaintenancesList from "./MaintenancesList"

const AllMaintenances = () => {
    const navigate = useNavigate();

    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [companies, setCompanies] = useState();
    const [contracts, setContracts] = useState();
    const [selectedContract, setSelectedContract] = useState();


    const getData =  () => {
         MaintenanceAPI.getCompaniesWithContract().then(function (res) {
            setCompanies(res.data);
            setIsLoading(false)
        }).catch(function (err) {
            if (err.response.status === 401 || err.response.status === 403) {
                navigate("/profil");
            } else {
                setIsLoading(false)
                setApiErr("Une erreur est survenue")
            }
        });
    }

    const displaySecondDropdown = (e) => {
        let selectedCompany = companies.find(el => el.id == e.target.value)
        setContracts(selectedCompany.maintenanceContracts);
        setSelectedContract("")
    }


    useEffect(
        () => {
            document.title = "Devolie - Les maintenances"
            if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");

            if (!isLoggedIn()) {
                navigate("/");
            } else {
                getData();
            }
        },
        []
    );

    return (
        <div>
            <div className="header-with-cta">
                <h1>Les maintenances</h1>
                <NavLink to="/maintenance/ajouter">
                    <button className="btn btn-primary create-item-btn"><AddOutlinedIcon />Ajouter un contrat</button>
                </NavLink>
                <NavLink to="/maintenance/contrat/supprimer">
                    <button className="btn btn-delete">Supprimer</button>
                </NavLink>
            </div>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !companies && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {!isLoading && companies && (
                <main>
                    <div className="maintenances-header-select">
                        <div className="">
                            <select onChange={displaySecondDropdown}>
                                <option selected={true} value="" disabled>Client</option>
                                {
                                    companies.map(company => <option value={company.id} key={company.id + company.companyName} >{company.companyName}</option>)
                                }
                            </select>

                        </div>

                        {
                            contracts && (
                                <div>
                                    <select onChange={e => setSelectedContract(e.target.value)} value={selectedContract}>
                                        <option selected={true} value="" disabled>Contrat</option>
                                        {
                                            contracts.map(contract => <option value={contract.id} key={contract.id + contract.dateStart} >Contrat {new Date(contract.dateStart).getMonth() +1 + "/" + new Date(contract.dateStart).getFullYear()}</option>)
                                        }
                                    </select>
                                </div>
                            )
                        }
                    </div>

                    {selectedContract && <MaintenancesList contractId={selectedContract} />}
                </main>
            )}

        </div>

    );
};

export default AllMaintenances;