import {axiosPrivateInstance} from './axiosConfig'

export const MaintenanceAPI ={

    addMaintenanceContract: function(maintenance) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/maintenance/contract/add`,
            data: maintenance,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
            }
        });
    },

    addMaintenance: function(maintenance) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/maintenance/add`,
            data: maintenance,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',
            }
        });
    },

    
    editMaintenance: function(maintenance) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/maintenance/edit`,
            data: maintenance,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',
            }
        });
    },


    getCompaniesWithContract: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/maintenance/companies`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getMonthlyMaintenances: function(id) {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/maintenance/contract/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getCompanyContracts: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/maintenance/contracts`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    deleteContract: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/contract/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
}

export default MaintenanceAPI