import "./parameters.scss"
import React, { useState } from "react";
import AllCompanies from "./company/AllCompanies";
import AllTicketStatus from "./ticketStatus/AllTicketStatus";
import AllInvoiceStatus from "./invoiceStatus/AllInvoiceStatus";
import AllPriorities from "./priority/AllPriorities";
import AllCategories from "./category/AllCategories";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useEffect } from "react";

const AllParameters = () => {
    const [selectedParameter, setSelectedParameter] = useState();
    const parameters = [<AllCompanies />, <AllPriorities />, <AllCategories />, <AllTicketStatus />, <AllInvoiceStatus />]

    useEffect(
        () => {
            document.title = "Devolie - Les paramètres"
        },
        []
    );
    return (
        <>
            <h1>Paramètres</h1>
            <div className="settings-container">
                <div className="settings-list">
                    <div className="icon"><SettingsOutlinedIcon /></div>
                    <ul>
                        <li onClick={() => setSelectedParameter(0)}>Entreprises</li>
                        <li onClick={() => setSelectedParameter(1)}>Priorités</li>
                        <li onClick={() => setSelectedParameter(2)}>Catégories</li>
                        <li onClick={() => setSelectedParameter(3)}>Status Ticket</li>
                        <li onClick={() => setSelectedParameter(4)}>Status Facture</li>
                    </ul>
                </div>
                {(selectedParameter === 0 || selectedParameter) && (
                    <div className="settings-display">
                        {parameters[selectedParameter]}
                    </div>
                )}
            </div>
        </>
    );
};

export default AllParameters;