import React, { useEffect, useState, useRef } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MaintenanceAPI } from "./../../../apis/MaintenanceAPI";
import AddMaintenanceForm from "./AddMaintenanceForm";
import MaintenanceItem from "./MaintenanceItem";
import { orderByDate } from "../../../utils/date"


const MaintenanceList = ({ contractId }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [maintenances, setMaintenances] = useState()
    const [displayForm, setDisplayForm] = useState(false)
    const messagesEndRef = useRef(null);


    const getMaintenances = () => {
        MaintenanceAPI.getMonthlyMaintenances(contractId).then((response) => {
            let orderedMaintenances = orderByDate(response.data)
            setMaintenances(orderedMaintenances)
            setIsLoading(false)
            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView();
            }, 100);
        })
            .catch(() => {
                setApiErr("Une erreur est survenue")
            });
    };


    useEffect(
        () => {
            getMaintenances();
        },
        []
    );

    return (
        <div>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !maintenances && (
                <p className="api-err">Une erreur est survenue.</p>
            )}
            <div className="maintenances-container">
                {!isLoading && maintenances.length > 0 && (
                    <div className="maintenance-list">
                        {maintenances.map((maintenance) => <MaintenanceItem maintenance={maintenance} setMaintenances={setMaintenances} />)}
                    </div>
                )}
                {!isLoading && !displayForm && (
                    <div className="btn-add-maintenance" onClick={(e) => setDisplayForm(true)}>
                        <AddOutlinedIcon />
                    </div>
                )}
                {
                    displayForm && <AddMaintenanceForm contractId={contractId} setMaintenances={setMaintenances} setDisplayForm={setDisplayForm} />
                }
                <div ref={messagesEndRef} />
            </div>
        </div>

    );
};

export default MaintenanceList;