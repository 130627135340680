import React, { useState } from "react";
import { CategoryAPI } from "./../../../apis/CategoryAPI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';



const AddCategoryForm = ({ setAllCategories, setDisplayAddForm }) => {

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [categoryData, setCategoryData] = useState({ categoryName: "" });

    const addCategory = () => {
        setIsSubmitting(true)
        if (categoryData.categoryName === "") {
            setApiErr("Veullez spécifier la catégorie.");
            return
        }

        CategoryAPI.addCategory(categoryData).then((response) => {
            setAllCategories(response.data)
            setDisplayAddForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    return (
        <div>
            <div className="modal modal-add">
                <div className="close-icon" onClick={() => setDisplayAddForm(false)}><CloseOutlinedIcon /></div>
                <label>Catégorie</label>
                <div>
                    <input type="text" onChange={(e) => setCategoryData({ categoryName: e.target.value })} value={categoryData.categoryName} />
                </div>

                {apiErr && (
                    <div className="alert"><ErrorOutlineOutlinedIcon />{apiErr}</div>
                )}
                <button disabled={isSubmitting} className="btn btn-primary create-item-btn" onClick={addCategory}>{(isSubmitting && ("Ajout...")) || ("Ajouter")}</button>
            </div>
        </div>
    );
};

export default AddCategoryForm;