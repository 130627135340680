import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import userContext from "./../../context/userContext";


function InvoiceItem({ invoice }) {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);

    const invoiceBg = {
        backgroundColor : invoice.status?.color,
        opacity: invoice.status?.color?  0.6 : 1,
    }

    const handleClick = (e) => {
        if(userLoggedIn.roles[0] === "ROLE_COLLABORATOR" && (invoice.status?.statusName === "Refusée" || invoice.status?.statusName === "Réglée")) return;
        let invoiceID = e.target.closest('tr').getAttribute("invoiceid");

        navigate("/facture/" + invoiceID)
    }

    return (
        <tr className={`list-item ${userLoggedIn.roles[0] === "ROLE_COLLABORATOR" && "list-item--padding"}`} invoiceid={invoice.id} key={invoice.id} onClick={handleClick}  >
            {userLoggedIn.roles[0] === "ROLE_ADMIN" && (
                <td style={invoiceBg} className="author-infos">
                    <div className="avatar-container">
                        <img src={require('./../../assets/images/avatars/' + invoice.collaborator.avatar.filename)} alt="Avatar" />
                    </div>
                    <p>{invoice.collaborator.firstname}</p>
                </td>
            )}
            <td style={invoiceBg}>{String(invoice.id).padStart(3, '0')}</td>
            <td style={invoiceBg}>{invoice.client.companyName}</td>
            <td style={invoiceBg}>{new Date(invoice.createdAt).toLocaleDateString()}</td>
            <td style={invoiceBg}>{invoice.status?.statusName ? invoice.status.statusName : ""}</td>
            <td style={invoiceBg} >{invoice.amount}€</td>
            <td style={invoiceBg}>{invoice.schoolDate ? invoice.schoolDate : ""}</td>
        </tr>
    )
}

export default InvoiceItem;