import React, { useState, useContext } from "react";
import logo from './../assets/images/logo.png'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import "./menu.scss"
import { NavLink, useNavigate } from "react-router-dom";
import { UserAPI } from "./../apis/UserAPI";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import IndexContext from "./../context/indexContext";

const Menu = ({ menuValue, setMenu, user }) => {
  const navigate = useNavigate();
  const [isLogingOut, setIsLogingOut] = useState(false);
  const {displayIndex} = useContext(IndexContext)

  const logout = () => {
    setIsLogingOut(true)
    UserAPI.logout().then(
      function () {
        localStorage.removeItem('token')
        navigate("/");
      }
    ).catch(function (error) {
      console.log("menu" + error)

    });
  }

  return (
    <header className={`sidebar-header  ${menuValue ? "menu-open" : ""}`}>
      <div className="sidebar-header--top">
        <div className="logo-container"><img src={logo} alt="logo" /></div>
        <nav className="nav-container">
          <div className="nav-general">
            <h2>Général</h2>
            <ul>
              <li className={displayIndex.ticket ? "to-handle" : ""}>
                <NavLink to="/tickets" onClick={() => setMenu(false)}>
                  <span className="material-icons"><BookOutlinedIcon /></span>Tickets
                </NavLink>
              </li>

              {user.roles[0] !== "ROLE_CLIENT" && (
                <li  className={displayIndex.invoice ? "to-handle" : ""}>
                  <NavLink to="/factures" onClick={() => setMenu(false)}>
                    <span className="material-icons"><ReceiptLongOutlinedIcon /></span>Factures
                  </NavLink>
                </li>
              )}

              {user.roles[0] === "ROLE_ADMIN" && (
                <div>
                  <li>
                    <NavLink to="/utilisateurs" onClick={() => setMenu(false)}>
                      <span className="material-icons"><GroupsOutlinedIcon /></span>Utilisateurs
                    </NavLink>
                  </li>

                </div>
              )}

              {user.roles[0] !== "ROLE_COLLABORATOR" && (
                <li>
                  <NavLink to="/maintenances" onClick={() => setMenu(false)}>
                    <span className="material-icons"><CalendarMonthOutlinedIcon /></span>Maintenances
                  </NavLink>
                </li>

              )}

            </ul>
          </div>
          <div className="nav-params">
            <h2>Paramètres</h2>
            <ul>
              <li>
                <NavLink to="/profil" onClick={() => setMenu(false)}>
                  <span className="material-icons"><PersonOutlinedIcon /></span>Profil
                </NavLink>
              </li>

              {user.roles[0] === "ROLE_ADMIN" && (
                <li>
                  <NavLink to="/parametres" onClick={() => setMenu(false)}>
                    <span className="material-icons"><BuildOutlinedIcon /></span>Paramètres
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </nav>
        <div className="menu-toggle" onClick={() => setMenu(!menuValue)}>
          <DragHandleOutlinedIcon />
        </div>
      </div>

      <button disabled={isLogingOut} onClick={logout} className="btn btn-primary btn-logout"><span className="material-icons"><LogoutOutlinedIcon /></span>Déconnexion</button>


    </header>
  );
};

export default Menu;