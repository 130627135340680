import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import AppContainer from "./layout/AppContainer";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/edit/EditProfile";
import AllUsers from "./pages/users/AllUsers";
import React from "react";
import { useState, useEffect} from "react";
import IndexContext from "./context/indexContext";
import UserContext from "./context/userContext";
import EditUser from "./pages/users/edit/EditUser";
import AddUser from "./pages/users/add/AddUser";
import AddTicket from "./pages/ticket/add/AddTicket";
import TicketChat from "./pages/ticket/chat/Chat";
import AllTickets from "./pages/ticket/AllTickets";
import AllInvoices from "./pages/invoices/AllInvoices";
import AddInvoice from "./pages/invoices/add/AddInvoice";
import EditInvoice from "./pages/invoices/edit/EditInvoice";
import AllParameters from "./pages/parameters/AllParameters";
import AddMaintenanceContract from "./pages/maintenances/addContract/AddMaintenanceContract";
import MaintenancesPage from "./pages/maintenances/MaintenancesPage";
import ErrorPage from "./pages/errors/404";
import DeleteContract from "./pages/maintenances/admin/DeleteContract";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/profil",
    element: <AppContainer content={<Profile />} />,
  },
  {
    path: "/profil/modifier",
    element: <AppContainer content={<EditProfile />} />,
  },
  {
    path: "/utilisateurs",
    element: <AppContainer content={<AllUsers />} />,
  },
  {
    path: "/utilisateur/:id",
    element: <AppContainer content={<EditUser />} />,
  },
  {
    path: "/utilisateur/ajouter",
    element: <AppContainer content={<AddUser />} />,
  },

  {
    path: "/tickets",
    element: <AppContainer content={<AllTickets />} />,
  },

  {
    path: "/ticket/ajouter",
    element: <AppContainer content={<AddTicket />} />,
  },
  {
    path: "/ticket/:id",
    element: <AppContainer content={<TicketChat />} />,
  },
  {
    path: "/factures",
    element: <AppContainer content={<AllInvoices />} />,
  },
  {
    path: "/facture/ajouter",
    element: <AppContainer content={<AddInvoice />} />,
  },
  {
    path: "/facture/:id",
    element: <AppContainer content={<EditInvoice />} />,
  },

  {
    path: "/parametres",
    element: <AppContainer content={<AllParameters />} />,
  },
  {
    path: "/maintenance/ajouter",
    element: <AppContainer content={<AddMaintenanceContract />} />,
  },
  {
    path: "/maintenance/contrat/supprimer",
    element: <AppContainer content={<DeleteContract />} />,
  },
  {
    path: "/maintenances",
    element: <AppContainer content={<MaintenancesPage />} />,
  },
  {
    path: "*",
    element: <AppContainer content={<ErrorPage />} />,
  },
]);

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState({});
  const [displayIndex, setDisplayIndex] = useState({});

  useEffect(
    () => {

      document.title = "Devolie - Maintenance"

    },
    []
  );

  return (
    <UserContext.Provider value={{ userLoggedIn, setUserLoggedIn }}>
      <IndexContext.Provider value={{ displayIndex, setDisplayIndex }}>
        <RouterProvider router={router} />
      </IndexContext.Provider>
    </UserContext.Provider>
  );
}

export default App;