import React, { useState } from "react";
import { APIFiles } from "./..//../../utils/api";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { months } from "./../../../utils/date"
import { MaintenanceAPI } from "./../../../apis/MaintenanceAPI";
import { orderByDate } from "./../../../utils/date"


function LinkMaintenance({ file, url }) {
    return <a href={`${APIFiles}/${url}/${file}`} target="_blank" rel="noopener noreferrer" download><AttachFileOutlinedIcon />Le document</a>
}

function MaintenanceItem({ maintenance, setMaintenances }) {
    const maintenanceToUpdate = {
        id: maintenance.id,
        report: "",
        invoice: "",
        timeSpent: maintenance.timeSpent ? maintenance.timeSpent : "",
        isDone: maintenance.isDone
    }

    const [apiErr, setApiErr] = useState()
    const [displayForm, setDisplayForm] = useState(false)
    const [updatedMaintenance, setUpdatedMaintenance] = useState(maintenanceToUpdate)

    const submit = async () => {

        const formData = new FormData();
        formData.append('id', updatedMaintenance.id);
        formData.append('timeSpent', updatedMaintenance.timeSpent);
        formData.append('report', updatedMaintenance.report);
        formData.append('invoice', updatedMaintenance.invoice);
        formData.append('isDone', updatedMaintenance.isDone);

        console.log(updatedMaintenance)
        await MaintenanceAPI.editMaintenance(formData).then(
            function (res) {

                let orderedMaintenances = orderByDate(res.data)
                setMaintenances(orderedMaintenances);
                setDisplayForm(false)

            }
        ).catch(function (error) {
            setApiErr("Une erreur est survenue")
        });
    }

    return (

        <div>
            {!displayForm && (
                <div className={`maintenance-item  ${maintenance.isDone && "is-done"}`}>
                    <div className="date">
                        <p className="month">{months[parseInt(maintenance.month - 1)].slice(0, 3)}</p>
                        <p>{maintenance.year}</p>
                    </div>
                    <hr />

                    <div>
                        <p className="label">Compte Rendu</p>
                        <p>{maintenance.report ? <LinkMaintenance file={maintenance.report} url="monthlyReports" /> : "Non disponible"}</p>
                    </div>
                    <hr />

                    <div>
                        <p className="label">Facture</p>
                        <p>{maintenance.invoice ? <LinkMaintenance file={maintenance.invoice} url="invoicesForClient" /> : "Non disponible"}</p>
                    </div>
                    <hr />

                    <div>
                        <p className="label">Temps passé</p>
                        <p>{maintenance.timeSpent ? maintenance.timeSpent : "Non disponible"}</p>
                    </div>
                    <hr />

                    <div className="checkbox">
                        <input type="checkbox" id="isDone" checked={maintenance.isDone} />
                        <label for="isDone">Réglée</label>
                    </div>

                    <button className="btn btn-primary" onClick={() => setDisplayForm(true)}>Modifier</button>
                </div>
            )}
            {displayForm && (
                <div className="maintenance-item edit-maintenance-form">
                    <div className="date">
                        <p className="month">{months[parseInt(maintenance.month - 1)].slice(0, 3)}</p>
                        <p>{maintenance.year}</p>
                    </div>
                    <hr />

                    <div className="file-input">
                        <p className="label">Compte Rendu</p>
                        <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUpdatedMaintenance({ ...updatedMaintenance, report: e.target.files[0] })} />
                    </div>
                    <hr />

                    <div className="file-input">
                        <p className="label">Facture</p>
                        <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUpdatedMaintenance({ ...updatedMaintenance, invoice: e.target.files[0] })} />
                    </div>
                    <hr />

                    <div className="time-spent">
                        <p className="label">Temps passé</p>
                        <input type="text" onChange={(e) => setUpdatedMaintenance({ ...updatedMaintenance, timeSpent: e.target.value })} value={updatedMaintenance.timeSpent} />
                    </div>
                    <hr />

                    <div className="checkbox">
                        <input type="checkbox" onChange={(e) => setUpdatedMaintenance({ ...updatedMaintenance, isDone: e.target.checked })} checked={updatedMaintenance.isDone} />
                        <label>Réglée</label>
                    </div>

                    {apiErr && (
                        <p className="api-err">Une erreur est survenue.</p>
                    )}
                    <button className="btn btn-primary" onClick={submit}>Modifier</button>
                    <button className="btn btn-alert" onClick={() => { setDisplayForm(false); setUpdatedMaintenance(maintenanceToUpdate) }}>Annuler</button>
                </div>
            )}
        </div>

    )
}

export default MaintenanceItem;