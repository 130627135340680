const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

const get3yearsInterval = () => {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1]
}

const threeYearsInterval = get3yearsInterval();

const orderByDate = (data) => {
    return data.sort((m1, m2) => new Date(m1.year, m1.month) - new Date(m2.year, m2.month))
}

export { months, threeYearsInterval, orderByDate }