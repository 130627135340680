import { axiosPrivateInstance} from './axiosConfig'

export const PriorityAPI ={

    getAllPriorities: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/priorities`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    addPriority: function(priorityData) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/priority/add`,
            data: priorityData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    editPriority: function(priorityData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/priority/edit/${id}`,
            data: priorityData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    deletePriority: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/priority/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
}

export default PriorityAPI