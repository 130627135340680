import { useNavigate } from "react-router-dom";

function UserItem({ user }) {
    const navigate = useNavigate();

    const handleClick = (e) => {
        let userId = e.target.closest('tr').getAttribute("userid");

        navigate("/utilisateur/" + userId)
    }

    return (
        <tr className="list-item ticket-item" userid={user.id}  onClick={handleClick}  >
            <td className="avatar">
                <div className="avatar-container avatar-container--user"><img src={require('./../../assets/images/avatars/' + user.avatar.filename)} alt="Avatar" /></div>
            </td>
            <td className="name">{user.firstname} {user.lastname} </td>
            <td className="phone">{user.phone} </td>
            <td className="role">
                {user.roles[0].slice(5)[0] + user.roles[0].slice(6).toLowerCase()}
            </td>
            <td className="company">{user.company? user.company.companyName : ""}</td>
            <td className="website">{user.website}</td>
            <td className="email">{user.email}</td>
        </tr>
    )
}

export default UserItem;