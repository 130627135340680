import React, {useEffect} from "react";



const NotFound = () => {
    useEffect(
        () => {
            document.title = "404 - Non trouvé"
        },
        []
    );

    return (
        <div className="body-404">
            <div class="content-404">
                <h1>Erreur 404</h1>

                <p> La page que vous recherchez semble introuvable</p>
            </div>

        </div>
    );
};

export default NotFound;