import {axiosPrivateInstance} from './axiosConfig'

export const InvoiceAPI ={
    
    getAllInvoices: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/invoices`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getInvoiceById: function(id) {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/invoice/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    addInvoice: function(invoice) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/invoice/add`,
            data: invoice,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',
            }
        });
    },

    updateInvoice: function(invoiceData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/invoice/edit/${id}`,
            data: invoiceData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',

            }
        });
    },

    deleteInvoice: function(id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/invoice/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    updateStatus: function(statusId, invoiceId) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/invoice/editStatus/${invoiceId}`,
            data: statusId,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
            }
        });
    },

    // Status parameters 
    addStatus: function(statusData) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/invoice/status/add`,
            data: statusData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    editStatus: function(statusData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/invoice/status/edit/${id}`,
            data: statusData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    deleteStatus: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/invoice/status/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
      getAllStatus: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/invoice/status`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },



}

export default InvoiceAPI