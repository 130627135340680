import { axiosPrivateInstance} from './axiosConfig'

export const CompanyAPI ={

    getAllCompanies: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/companies`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    addCompany: function(companyData) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/company/add`,
            data: companyData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    editCompany: function(companyData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/company/edit/${id}`,
            data: companyData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    deleteCompany: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/company/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
}

export default CompanyAPI