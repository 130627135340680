import React, { useContext, useEffect } from "react";
import userContext from "./../../context/userContext";
import './profile.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from "react-router-dom";

const Profile = () => {
    const { userLoggedIn } = useContext(userContext);

    useEffect(() => {
        document.title = "Devolie - Mon profil"
    }, []);
    
    return (
        <>
            <div className="profile-container">
                <Link to="/profil/modifier">
                    <h1>Mon profil</h1>
                    <div className="profile-card">
                        <div className="avatar-container"><img src={require('./../../assets/images/avatars/' + userLoggedIn.avatar.filename)} alt="Avatar" /></div>
                        <p className="name"><span>{userLoggedIn.nom}</span> <span>{userLoggedIn.firstname}</span></p>
                        <p className="company">{userLoggedIn.company ? userLoggedIn.company.companyName : ""}</p>
                        {userLoggedIn.phone && <p className="phone">{userLoggedIn.phone}</p>}
                        <p className="email">{userLoggedIn.email}</p>
                        <p>************</p>
                        <p className="website">{userLoggedIn.website}</p>
                    </div>
                </Link>
            </div>

            <div className="btn-container--center">
                <Link to="/profil/modifier">
                    <div className="btn btn-primary edit-profile-btn">
                        <span className="material-icons"><EditOutlinedIcon /></span>
                        <p>Modifier</p>
                    </div>
                </Link>
            </div>
        </>

    );
};

export default Profile;