import { createContext } from "react";

const IndexContext = createContext({
    displayIndex: {
        ticket : false,
        invoice: false
    } ,
    setDisplayIndex: (data) => {}
});

export default IndexContext;