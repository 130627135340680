import React, { useEffect, useContext, useState } from "react";
import Menu from "./Menu";
import { UserAPI } from "./../apis/UserAPI";
import { TicketAPI } from "../apis/TicketAPI";
import { InvoiceAPI } from "../apis/InvoiceAPI";
import userContext from "./../context/userContext";
import IndexContext from "./../context/indexContext";
import { useNavigate } from "react-router-dom";
import isLoggedIn from "./../utils/isLoggedIn";
import logo from './../assets/images/logo.png'
import { NavLink } from "react-router-dom";
import { displayIndexOrNot } from "./../utils/indexColor";


const AppContainer = ({ content }) => {
  const navigate = useNavigate();

  const { userLoggedIn, setUserLoggedIn } = useContext(userContext)
  const { displayIndex, setDisplayIndex } = useContext(IndexContext)
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true)

  const getData = () => {
    Promise.all([UserAPI.getUser(), TicketAPI.getAllTickets(), InvoiceAPI.getAllInvoices()])
      .then(function (results) {
        setUserLoggedIn(results[0].data)
        setDisplayIndex({
          ...displayIndex,
          ticket: displayIndexOrNot(results[1].data, "Résolu")
        })
        setDisplayIndex({
          ...displayIndex,
          invoice: displayIndexOrNot(results[2].data)

        })
        setIsLoading(false)

      }).catch(function () {
        localStorage.removeItem("token");
        navigate("/");
      })
  };



  useEffect(
    () => {
      if (!isLoggedIn()) {
        navigate("/");
      } else {
        getData();

      }
    },
    []
  );

  return (
    <div className={`app-container ${isLoading && "loading"}`}>
      {isLoading && (
        <div className="loading-container">
          <div className="logo-container"><img src={logo} alt="Logo" /></div>
          <p>Chargement</p>
        </div>
      )}
      {
        !isLoading && (
          <>
            <Menu menuValue={menuIsOpen} setMenu={setMenuIsOpen} user={userLoggedIn} />
            <div className={`app-container--inner ${menuIsOpen && "app-low-opacity"}`}>
              <NavLink to="/profil">
                <div className="welcome-msg">
                  <p>Bonjour <span className="name">{userLoggedIn.firstname}</span></p>
                  <div className="avatar-container"><img src={require('./../assets/images/avatars/' + userLoggedIn.avatar.filename)} alt="Avatar" /></div>
                </div>
              </NavLink>
              <div className="app-content">
                {content}
              </div>
            </div>
          </>
        )
      }
    </div>

  );
};

export default AppContainer;