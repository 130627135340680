import React, { useState, useEffect } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TicketAPI } from "../../../apis/TicketAPI";
import { useNavigate } from "react-router-dom";

const DeleteTicket = ({ ticket }) => {
    const navigate = useNavigate();
    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [displayModal, setDislayModal] = useState(false)

    const deleteTicket = async () => {
        setIsSubmitting(true)
        await TicketAPI.deleteTicket(ticket.id).then(
            function (res) {
                navigate("/tickets")
            }
        ).catch(function () {
            setApiErr("Une erreur est survenue")
        });
    }

    useEffect(() => {
      console.log(ticket)
    }, []);
    return (
        <div>
            <div className="btn btn-delete btn-modal" onClick={() => setDislayModal(true)}>Supprimer le ticket</div>
            {
                displayModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDislayModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer ce ticket ?</p>
                        <button onClick={deleteTicket} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }

        </div>
    );
};

export default DeleteTicket;