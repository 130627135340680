import React, { useContext, useEffect, useRef } from "react";
import userContext from "./../../../context/userContext";
import { APIFiles } from "../../../utils/api";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

const MessagesContainer = ({ ticket }) => {
    const { userLoggedIn } = useContext(userContext);
    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView();
      }, [ticket]);
    return (
        <div className="echanges-container">
            {
                ticket.messages.map(message => (
                    <div className={`message-detail ${userLoggedIn.id === message.author.id ? "message-right" : ""} ${userLoggedIn.company?.companyName === message.author.company?.companyName ? "message-right" : ""}`} key={message.id}>
                        <div className="top">
                            <div className="avatar-container"><img src={require('./../../../assets/images/avatars/' + message.author.avatar.filename)} alt="Avatar" /></div>
                           <pre className="message-content">{message.content}</pre>
                        </div>
                        <p className="date">{`${message.author.firstname} - ${new Date(message.created_at).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}`}</p>
                        {
                            message.uploads && message.uploads.map(upload => (
                                <a key={upload.id} className="upload-link" href={`${APIFiles}/uploads/${upload.filename}`} target="_blank" rel="noopener noreferrer"><AttachFileOutlinedIcon/>{upload.filename.slice(0,25) + "..."}</a>
                            ))
                        }
                    </div>
                ))
            }
             <div ref={messagesEndRef} />
        </div >
    );
};

export default MessagesContainer;