import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceAPI } from "../../../apis/InvoiceAPI";
import { CompanyAPI } from "../../../apis/CompanyAPI";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import userContext from "./../../../context/userContext";
import { months, threeYearsInterval } from "./../../../utils/date"

function AddInvoice() {
    const navigate = useNavigate();

    const emptyInvoice = {
        client: {
            "id": ""
        },
        amount: "",
        schoolDate: ""
    }

    const uploadsData = {
        fileInput1: "",
        fileInput2: ""
    }

    const schoolDateData = {
        display: false,
        month: "",
        year: ""
    }

    const { userLoggedIn } = useContext(userContext);
    const [invoice, setInvoice] = useState(emptyInvoice)
    const [isLoading, setIsLoading] = useState(true)
    const [clients, setClients] = useState()
    const [uploads, setUploads] = useState(uploadsData);
    const [schoolDate, setSchoolDate] = useState(schoolDateData);
    const [apiErr, setApiErr] = useState("")

    const getClients = () => {
        setApiErr("");
        CompanyAPI.getAllCompanies().then((response) => {
            setIsLoading(false)
            setClients(response.data)
        })
            .catch(() => {
                setApiErr("Une erreur est survenue");
                setIsLoading(false)
            });
    };

    const selectClient = (e) => {
        setInvoice({ ...invoice, client: { id: Number(e.target.value) } });
        let isClient = e.target.options[e.target.selectedIndex].getAttribute('isclient');
        isClient === "true" ? setSchoolDate({ ...schoolDate, display: true }) : setSchoolDate({ ...schoolDate, display: false })

    }

    const yupSchema = yup.object({
        amount: yup.number().typeError("Veuillez renseigner un montant valide.").required("Veuillez remplir ce champ."),
        selectClient: yup.string().required("Veuillez choisir un client."),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit = async () => {
        const formData = new FormData();

        formData.append('client', invoice.client.id);
        formData.append('amount', invoice.amount);
        formData.append('schoolDate', schoolDate.month + "/" + schoolDate.year);
        formData.append('file1', uploads.fileInput1);
        formData.append('file2', uploads.fileInput2);

        await InvoiceAPI.addInvoice(formData).then(
            function (res) {
                navigate("/factures")
            }
        ).catch(function (error) {
            setError('globalError', { type: 'global', message: "Une erreur est survenue" });
        });
    }


    useEffect(() => {
        document.title = "Devolie - Déposer une facture"
        if (userLoggedIn.roles[0] !== "ROLE_COLLABORATOR") navigate("/profil");
        getClients()
    }, []);

    return (
        <main>

            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            <h1>Déposer une facture</h1>
            {apiErr && (
                <p className="api-err alert"><ErrorOutlineOutlinedIcon />{apiErr}</p>
            )}
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {!isLoading && clients && (
                <form onSubmit={handleSubmit(submit)}>

                    <div className="select-container form-input">
                        <label>Client*</label>
                        <select  {...register("selectClient")} onChange={selectClient} value={invoice.client.id}>
                            <option selected={true} value="" disabled>Choisir</option>
                            {
                                clients.map(client => <option value={client.id} key={client.id + client.companyName} isclient={`${client.isSchool}`}>{client.companyName}</option>)
                            }
                        </select>
                    </div>
                    {errors?.selectClient && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectClient.message}</div>
                    )}

                    {schoolDate.display && (
                        <div>
                            <div className="select-container form-input">
                                <label>Mois*</label>
                                <select onChange={(e) => setSchoolDate({ ...schoolDate, month: e.target.value })} value={schoolDate.month}>
                                    <option selected={true} value="" disabled>Choisir</option>
                                    {
                                        months.map((month, i) => <option value={String(1 + i).padStart(2, '0')} key={i + month}>{month}</option>)
                                    }
                                </select>
                            </div>
                            <div className="select-container form-input">
                                <label>Année*</label>
                                <select onChange={(e) => setSchoolDate({ ...schoolDate, year: e.target.value })} value={schoolDate.year}>
                                    <option selected={true} value="" disabled>Choisir</option>
                                    {
                                        threeYearsInterval.map((year, i) => <option value={year} key={i + year}>{year}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                    )}

                    <div>
                        <div className="form-input">
                            <label>Montant TTC*</label>
                            <input {...register("amount")} type="text" onChange={(e) => setInvoice({ ...invoice, amount: e.target.value })} value={invoice.amount} />
                        </div>
                        {errors?.amount && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.amount.message}</div>
                        )}
                    </div>


                    <div>
                        <div className="form-input">
                            <label>Facture*</label>
                            <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUploads({ ...uploads, fileInput1: e.target.files[0] })} />
                        </div>
                        <div className="form-input">
                            <label>Autre fichier</label>
                            <input type="file" accept="image/png, image/jpeg,.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => setUploads({ ...uploads, fileInput2: e.target.files[0] })} />
                        </div>

                        <div className="upload-info">
                            <p>Taille de fichier maximale: 4MB</p>
                            <p>Formats acceptés : png, jpg, pdf, doc, ppt, xls </p>
                        </div>

                    </div>

                    {errors?.globalError && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                    )}
                    <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Dépôt en cours...")) || ("Déposer la facture")}</button>
                </form>
            )
            }

        </main >
    )
}

export default AddInvoice;