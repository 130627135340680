import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import isLoggedIn from "../../../utils/isLoggedIn";
import userContext from "../../../context/userContext";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCategoryForm from "./AddCategoryForm";
import CategoryItem from "./CategoryItem";

const Categories = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [allCategories, setAllCategories] = useState();
    const [displayAddForm, setDisplayAddForm] = useState(false);


    const getCategories = () => {
        CategoryAPI.getAllCategories().then((response) => {
            setIsLoading(false)
            setAllCategories(response.data)
        })
            .catch((err) => {
                console.log(err)

            });
    };




    useEffect(
        () => {
            if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");
            document.title = "Devolie - Les catégories"
            if (!isLoggedIn()) {
                navigate("/");
            } else {
                getCategories();
            }
        },
        []
    );

    return (
        <div>
            <div className="header-with-cta">
                <h2>Les catégories</h2>
                <button onClick={() => setDisplayAddForm(true)} className="btn btn-primary create-item-btn"><AddOutlinedIcon />Ajouter</button>
            </div>
            <hr />
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && (
                <p className="loading">Une erreur est survenue.</p>
            )}

            {!isLoading && allCategories && allCategories.length > 1 && (
                <ul>
                    {allCategories.map(category => <CategoryItem category={category} setAllCategories={setAllCategories} />)}
                </ul>
            )}

            {!isLoading && allCategories && !Array.isArray(allCategories) && (
                <ul>
                    <CategoryItem company={allCategories} setAllCategories={setAllCategories} />
                </ul>
            )}


            {
                displayAddForm && <AddCategoryForm setDisplayAddForm={setDisplayAddForm} setAllCategories={setAllCategories} />
            }

        </div>

    );
};

export default Categories;