import "./addTicket.scss"
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PriorityAPI } from "../../../apis/PriorityAPI";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { TicketAPI } from "../../../apis/TicketAPI";
import { useForm } from "react-hook-form";
import userContext from "./../../../context/userContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import isLoggedIn from "./../../../utils/isLoggedIn";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function AddTicket() {
    const navigate = useNavigate();

    const emptyTicket = {
        category: {
            id: ""
        },
        priority: {
            id: ""
        },
        subject: "",
        message: {
            content: ""
        }
    }
    const uploadsData = {
        fileInput1: {
            show: false,
            file: ""
        },
        fileInput2: {
            show: false,
            file: ""
        },
        fileInput3: {
            show: false,
            file: ""
        },
        fileInput4: {
            show: false,
            file: ""
        },
        inputShown: 0
    }

    const { userLoggedIn } = useContext(userContext);
    const [ticket, setTicket] = useState(emptyTicket);
    const [categories, setCategories] = useState();
    const [priorities, setPriorities] = useState();
    const [uploads, setUploads] = useState(uploadsData);
    const [showAddFiles, setShowAddFiles] = useState(true);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()


    const yupSchema = yup.object({
        object: yup.string().required("Veuillez remplir ce champ.").max(50, "L'objet de votre ticket est trop long (50 caractères maximum). Veuillez apporter plus de détails dans le corps du message."),
        message: yup.string().required("Veuillez remplir ce champ.").max(1000, "Votre message doit faire 1000 caractères maximum."),
        selectCategory: yup.string().required("Veuillez choisir une catégorie."),
        selectPriority: yup.string().required("Veuillez choisir une priorité."),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError, clearErrors } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const getCategoriesAndPriorities = () => {
        CategoryAPI.getAllCategories().then((response) => {
            setCategories(response.data);
            PriorityAPI.getAllPriorities().then((res) => {
                setPriorities(res.data);
                setIsLoading(false)
            })
                .catch(() => {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                });
        })
            .catch(() => {
                setIsLoading(false)
                setApiErr("Une erreur est survenue")
            });
    };

    const submit = async () => {
        const formData = new FormData();
        formData.append('file1', uploads.fileInput1.file);
        formData.append('file2', uploads.fileInput2.file);
        formData.append('file3', uploads.fileInput3.file);
        formData.append('file4', uploads.fileInput4.file);

        formData.append('category', ticket.category.id);
        formData.append('priority', ticket.priority.id);
        formData.append('content', ticket.message.content);
        formData.append('subject', ticket.subject);

        await TicketAPI.addTicket(formData).then(
            function (res) {
               navigate("/ticket/" + res.data )
            }
        ).catch(function () {
            setError('globalError', { type: 'global', message: "Veuillez renseigner des données valides." });

        });
    }

    const showFileInput = () => {
        switch (uploads.inputShown) {
            case 0:
                setUploads({ ...uploads, fileInput1: { show: true }, inputShown: 1 });
                break;
            case 1:
                setUploads({ ...uploads, fileInput2: { show: true }, inputShown: 2 })
                break;
            case 2:
                setUploads({ ...uploads, fileInput3: { show: true }, inputShown: 3 })
                break;
            case 3:
                setUploads({ ...uploads, fileInput4: { show: true }, inputShown: 4 });
                setShowAddFiles(false)
                break;
            default:
                console.log("Erreur affichage input");
        }
    }

    useEffect(
        () => {
            document.title = "Devolie - Créer un ticket";
            if (!isLoggedIn()) {
                navigate("/");
            } else {
                if (userLoggedIn.roles[0] !== "ROLE_CLIENT") navigate("/profil");

                getCategoriesAndPriorities();
            }
        },
        []
    );

    return (
        <main>

            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            <h1>Créer un ticket</h1>

            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
         
            {errors?.globalError && (
                    <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                )}
            {
                categories && priorities && (
                    <form className="form-add--ticket" onSubmit={handleSubmit(submit)}>

                        <div className="first-row">
                            <div className="select-input">
                                <label>Catégorie<KeyboardArrowDownOutlinedIcon /></label>
                                <select {...register("selectCategory")} onChange={(e) => { setTicket({ ...ticket, category: { id: Number(e.target.value) } }); }} value={ticket.category.id} >
                                    <option value="" disabled>Choisir</option>
                                    {
                                        categories && Object.keys(categories).map((key, i) => <option value={categories[key].id} key={categories[key].categoryName}>{categories[key].categoryName}</option>)
                                    }
                                </select>
                                {errors?.selectCategory && (
                                    <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectCategory.message}</div>
                                )}
                            </div>
                            <div className="select-input">
                                <label>Priorité <KeyboardArrowDownOutlinedIcon /></label>
                                <select {...register("selectPriority")} onChange={(e) => { setTicket({ ...ticket, priority: { id: Number(e.target.value) } }); }} value={ticket.priority.id} >
                                    <option  value="" disabled>Choisir</option>

                                    {
                                        priorities && Object.keys(priorities).map((key, i) => <option value={priorities[key].id} key={priorities[key].priorityName}>{priorities[key].priorityName}</option>)
                                    }
                                </select>

                                {errors?.selectPriority && (
                                    <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectPriority.message}</div>
                                )}
                            </div>
                        </div>

                        <div className="second-row">
                            <div className="form-input">
                                <label>Objet</label>
                                <input {...register("object")} type="text" onChange={(e) => setTicket({ ...ticket, subject: e.target.value })} value={ticket.subject} placeholder="Quelle est votre demande ?" />
                            </div>
                            {errors?.object && (
                                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.object.message}</div>
                            )}
                        </div>

                        <div>
                            <div className="text-input">
                                <label>Message</label>
                                <textarea {...register("message")} onChange={(e) => setTicket({ ...ticket, message: { content: e.target.value } })} value={ticket.message.content} />
                            </div>
                            {errors?.message && (
                                <div className="alert"><ErrorOutlineOutlinedIcon />{errors.message.message}</div>
                            )}
                        </div>

                        <div className="uploads-container">
                            {uploads.fileInput1.show && (<input type="file" onChange={(e) => uploads.fileInput1.file = e.target.files[0]} />)}
                            {uploads.fileInput2.show && (<input type="file" onChange={(e) => uploads.fileInput2.file = e.target.files[0]} />)}
                            {uploads.fileInput3.show && (<input type="file" onChange={(e) => uploads.fileInput3.file = e.target.files[0]} />)}
                            {uploads.fileInput4.show && (<input type="file" onChange={(e) => uploads.fileInput4.file = e.target.files[0]} />)}
                            {uploads.fileInput1.show && (
                                <div className="upload-info">
                                    <p>Taille de fichier maximale: 4MB</p>
                                    <p>Formats acceptés : png, jpg, pdf, doc, ppt, xls </p>
                                </div>
                            )}
                        </div>

                        {showAddFiles && (<div className="add-file" onClick={showFileInput}><AddOutlinedIcon />{(uploads.fileInput1.show && `Joindre un autre fichier (${4 - uploads.inputShown} restant(s))`) || "Joindre un fichier (4 max)"}</div>)}

                        <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Envoi...")) || ("Envoyer")}</button>
                    </form>
                )
            }
        </main>
    )
}

export default AddTicket;