import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { CompanyAPI } from "./../../../apis/CompanyAPI";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function CompanyItem({ company, setAllCompanies }) {
    const [displayEditForm, setDisplayEditForm] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false)

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [companyToUpdate, setCompanyToUpdate] = useState({
        companyName: company.companyName,
        isSchool: company.isSchool,
    })

    const editCompany = () => {
        setIsSubmitting(true)

        if (companyToUpdate.companyName === "") {
            setApiErr("Veullez spécifier le nom d'entreprise.");
            return
        }
        CompanyAPI.editCompany(companyToUpdate, company.id).then((response) => {
            setAllCompanies(response.data)
            setDisplayEditForm(false)
            setIsSubmitting(false)

        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)

            });
    };

    const deleteCompany = async () => {
        setIsSubmitting(true)
        await CompanyAPI.deleteCompany(company.id).then(
            function (response) {
                setAllCompanies(response.data)
                setIsSubmitting(false)
                setDisplayDeleteModal(false)

            }
        ).catch(function () {
            setApiErr("Une erreur est survenue");
            setIsSubmitting(false)

        });
    }
    return (
        <>
            {!displayEditForm && (
                <li className="list-item" key={company.id}>

                    <p className="company-info">{company.companyName} {company.isSchool && <SchoolOutlinedIcon />}</p>

                    <div>
                        <button className="btn btn-primary" onClick={() => { setDisplayEditForm(true) }}>Modifier</button>
                        <button onClick={() => { setDisplayDeleteModal(true) }} className="btn btn-delete">Supprimer</button>
                    </div>
                </li>
            )}
            {
                displayDeleteModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDisplayDeleteModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer cette entreprise ?</p>
                        <p>{company.companyName}</p>
                        <button onClick={deleteCompany} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }
            {displayEditForm && (
                <li className="edit-item">
                    <div className="edit-form">
                        <div className="company-input">
                            <input type="text" onChange={(e) => setCompanyToUpdate({ ...companyToUpdate, companyName: e.target.value })} value={companyToUpdate.companyName} />
                        </div>

                        <div className="checkbox">
                            <label>S'agit-il d'une école ?</label>
                            <input type="checkbox" onChange={(e) => setCompanyToUpdate({ ...companyToUpdate, isSchool: e.target.checked })} checked={companyToUpdate.isSchool} />
                        </div>
                    </div>
                    <div className="btn-container">
                        <button disabled={isSubmitting} className="btn btn-primary" onClick={editCompany}>{(isSubmitting && ("En cours...")) || ("Valider")}</button>
                        <button className="btn btn-delete" onClick={() => setDisplayEditForm(false)}>Annuler</button>
                    </div>
                </li>
            )}
        </>
    )
}

export default CompanyItem;