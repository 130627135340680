import { axiosPrivateInstance} from './axiosConfig'

export const StatusAPI ={

    getAllStatus: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/status`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    addStatus: function(statusData) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/status/add`,
            data: statusData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    editStatus: function(statusData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/status/edit/${id}`,
            data: statusData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    deleteStatus: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/status/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

}

export default StatusAPI