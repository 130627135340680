import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserAPI } from "../../../apis/UserAPI";
import { CompanyAPI } from "../../../apis/CompanyAPI";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import roles from "./../../../utils/roles"
import userContext from "./../../../context/userContext";

function AddUser() {
    const navigate = useNavigate();
    const avatars = ["cochon.png", "cerf.png", "koala.png", "lapin.png", "loup_blanc.png", "loup.png", "ours_blanc.png", "ours.png", "panda.png", "pingouin.png", "renard.png", "rennes.png", "zebre.png"];

    const emptyUser = {
        "avatar": {
            "filename": avatars[0]
        },
        "lastname": "",
        "firstname": "",
        "email": "",
        "password": "",
        "company": { id: "" },
        "website": "",
        "phone": "",
        "roles": ""
    }

    const { userLoggedIn } = useContext(userContext);
    const [user, setUser] = useState(emptyUser)
    const [isLoading, setIsLoading] = useState(true)
    const [companies, setCompanies] = useState()
    const [passwordShown, setPasswordShown] = useState({
        1: false,
        2: false,
    });
    const [selectedAvatar, setSelectedAvatar] = useState(avatars[0]);
    const [displayModal, setDisplayModal] = useState(false);

    const getCompanies = () => {
        CompanyAPI.getAllCompanies().then((response) => {
            setIsLoading(false)
            setCompanies(response.data)
        })
            .catch((err) => {
                console.log(err)
            });
    };

    const yupSchema = yup.object({
        firstname: yup.string().required("Veuillez remplir ce champ."),
        lastname: yup.string().required("Veuillez remplir ce champ."),
        email: yup.string().email("Veuillez renseigner un email valide").required("Veuillez remplir ce champ."),
        selectCompany: yup.number().required("Veuillez choisir une entreprise."),
        website: yup.string().required("Veuillez remplir ce champ."),
        password: yup.string().required("Veuillez remplir ce champ.").matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$", "Votre mot de passe doit faire au moins 8 caractères de long, contenir une majuscule et un chiffre."),
        confirmPassword: yup.string().required("Veuillez remplir ce champ.").oneOf(
            [yup.ref('password'), ''],
            'Les mots de passe ne correspondent pas'
        ),
    });

    const { register, handleSubmit, formState: { errors, isSubmitting }, setError, clearErrors } = useForm({
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit = async () => {
        await UserAPI.addUser(user).then(
            function () {
                navigate("/utilisateurs");
            }
        ).catch(function (error) {
            setError('globalError', { type: 'global', message: "Une erreur est survenue" });
        });
    }

    const togglePassword = (index) => {
        setPasswordShown({ ...passwordShown, [index]: !passwordShown[index] });
    };

    const cancelEditAvatar = () => {
        setDisplayModal(false);
    }

    useEffect(() => {
        document.title = "Devolie - Ajouter un utilisateur"
        if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");
        getCompanies()
    }, []);

    return (
        <main>

            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            <h1>Ajouter un utilisateur</h1>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {!isLoading && companies && (
                <form onSubmit={handleSubmit(submit)}>
                    <div className="current-avatar" onClick={() => setDisplayModal(true)}>
                        <p>Avatar</p>
                        <div className="avatar-img"><img src={require('./../../../assets/images/avatars/' + selectedAvatar)} alt="Avatar" /></div>
                    </div>
                    {displayModal && (
                        <div className="avatar-modal ">
                            <div className="avatars-container">
                                {avatars.map(avatar => {
                                    return <div className={`avatar-img ${selectedAvatar === avatar ? "selected" : ""} `} key={avatar} onClick={() => {
                                        setSelectedAvatar(avatar);
                                        setUser({ ...user, avatar: { filename: avatar } })
                                    }}>
                                        <img src={require('./../../../assets/images/avatars/' + avatar)} alt="Avatar" />
                                    </div>
                                })}
                            </div>
                            <button className="btn btn-primary" onClick={cancelEditAvatar}>Valider</button>
                        </div>
                    )}

                    <div className="select-container form-input">
                        <label>Rôle*</label>
                        <select name="roles" onChange={(e) => { setUser({ ...user, roles: [e.target.value] }); }} value={user.roles}>
                            <option selected={true} value="" disabled>Choisir</option>
                            {
                                Object.keys(roles).map((key, i) => <option value={roles[key]}>{key}</option>)
                            }
                        </select>
                    </div>
                    {errors?.selectRole && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectRole.message}</div>
                    )}

                    <div>
                        <div className="form-input">
                            <label>Nom*</label>
                            <input {...register("lastname")} type="text" onChange={(e) => setUser({ ...user, lastname: e.target.value })} value={user.lastname} />
                        </div>
                        {errors?.lastname && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.lastname.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="form-input">
                            <label>Prénom*</label>
                            <input {...register("firstname")} type="text" onChange={(e) => setUser({ ...user, firstname: e.target.value })} value={user.firstname} />
                        </div>
                        {errors?.firstname && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.firstname.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="form-input">
                            <label>Email*</label>
                            <input {...register("email")} type="text" onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} />
                        </div>
                        {errors?.email && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.email.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="select-container form-input">
                            <label>Entreprise*</label>
                            <select {...register("selectCompany")} onChange={(e) => { setUser({ ...user, company: { id: Number(e.target.value) } }); }} value={user.company.id}>
                                <option selected={true} value="" disabled>Choisir</option>
                                {
                                    companies.map(company => <option value={company.id} key={company.id + company.companyName}>{company.companyName}</option>)
                                }
                            </select>
                        </div>
                        {errors?.selectCompany && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.selectCompany.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="form-input">
                            <label>Site*</label>
                            <input {...register("website")} type="text" onChange={(e) => setUser({ ...user, website: e.target.value })} value={user.website} />
                        </div>
                        {errors?.website && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.website.message}</div>
                        )}
                    </div>

                    <div>
                        <div className="form-input">
                            <label>Téléphone</label>
                            <input type="text" onChange={(e) => setUser({ ...user, phone: e.target.value })} value={user.phone} />
                        </div>
                        {errors?.phone && (
                            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.phone.message}</div>
                        )}
                    </div>

                    <div className="password-input">
                        <input {...register("password")} type={passwordShown[1] ? "text" : "password"} onChange={(e) => setUser({ ...user, password: e.target.value })} value={user.password} placeholder="Mot de passe*" />
                        <div className="show-icon" onClick={() => togglePassword(1)}><RemoveRedEyeOutlinedIcon /></div>
                    </div>
                    {errors?.password && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.password.message}</div>
                    )}

                    <div className="password-input">
                        <input autoComplete="new-password" {...register("confirmPassword")} type={passwordShown[2] ? "text" : "password"} placeholder="Confirmation du mot de passe*" />
                        <div className="show-icon" onClick={() => togglePassword(2)}><RemoveRedEyeOutlinedIcon /></div>
                    </div>
                    {errors?.confirmPassword && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.confirmPassword.message}</div>
                    )}

                    {errors?.globalError && (
                        <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
                    )}
                    <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Modification...")) || ("Sauvegarder")}</button>
                </form>

            )}


        </main>
    )
}

export default AddUser;