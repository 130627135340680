import axios from 'axios';
import {APIBaseUrl} from "../utils/api"
import { UserAPI } from "./../apis/UserAPI";


const axiosInstance = axios.create({
   withCredentials: true,
   baseURL: `${APIBaseUrl}`
});


const axiosPrivateInstance = axios.create({
   withCredentials: true,
   baseURL: `${APIBaseUrl}`
});



axiosPrivateInstance.interceptors.request.use(async function (config) {
   let JWT = localStorage.getItem("token");
   const jwtPayload = JSON.parse(window.atob(JWT.split('.')[1]))
   if (Date.now() >= jwtPayload.exp * 1000) {
      try{
         const tokenResponse = await UserAPI.refreshToken();
         localStorage.setItem('token', tokenResponse.data.token)
         config.headers.Authorization = `Bearer ${tokenResponse.data.token}`;
         return config;
      } catch {
         localStorage.removeItem('token')
         window.location.href = "/";
      }
   }
   return config;  

 }, function (error) {
   return Promise.reject(error);
});


export {axiosInstance, axiosPrivateInstance};