import React, { useState } from "react";
import { PriorityAPI } from "./../../../apis/PriorityAPI";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function PriorityItem({ priority, setAllPriorities }) {
    const [displayEditForm, setDisplayEditForm] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false)

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [priorityToUpdate, setPriorityToUpdate] = useState({
        priorityName: priority.priorityName,
        color: priority.color ? priority.color : "",
    })

    const editPriority = () => {
        setIsSubmitting(true)

        if (priority.priorityName === "") {
            setApiErr("Veullez spécifier la priorité.");
            return
        }
        PriorityAPI.editPriority(priorityToUpdate, priority.id).then((response) => {
            setAllPriorities(response.data)
            setDisplayEditForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    const deletePriority = async () => {
        setIsSubmitting(true)
        await PriorityAPI.deletePriority(priority.id).then(
            function (response) {
                setAllPriorities(response.data)
                setIsSubmitting(false)
                setDisplayDeleteModal(false)

            }
        ).catch(function () {
            setApiErr("Une erreur est survenue");
            setIsSubmitting(false)
        });
    }

    return (
        <>
            {!displayEditForm && (
                <li className="list-item" key={priority.id}>

                    <p className="priority-label" style={{ backgroundColor: `${priority.color}` }}>{priority.priorityName}</p>

                    <div>
                        <button className="btn btn-primary" onClick={() => { setDisplayEditForm(true) }}>Modifier</button>
                        <button onClick={() => { setDisplayDeleteModal(true) }} className="btn btn-delete">Supprimer</button>
                    </div>
                </li>
            )}
            {
                displayDeleteModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDisplayDeleteModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer cette priorité ?</p>
                        <p>{priority.priorityName}</p>
                        <button onClick={deletePriority} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }
            {displayEditForm && (
                <li className="edit-item">
                    <div className="edit-form">
                        <div className="company-input">
                            <input type="text" onChange={(e) => setPriorityToUpdate({ ...priorityToUpdate, priorityName: e.target.value })} value={priorityToUpdate.priorityName} />
                        </div>
                        <input type="color" onChange={(e) => setPriorityToUpdate({ ...priorityToUpdate, color: e.target.value })} value={priorityToUpdate.color} />

                    </div>

                    <div className="btn-container">
                        <button disabled={isSubmitting} className="btn btn-primary" onClick={editPriority}>{(isSubmitting && ("En cours...")) || ("Valider")}</button>
                        <button className="btn btn-delete" onClick={() => setDisplayEditForm(false)}>Annuler</button>
                    </div>
                </li>
            )}
        </>
    )
}

export default PriorityItem;