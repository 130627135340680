import React, { useState } from "react";
import { InvoiceAPI } from "./../../../apis/InvoiceAPI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const AddStatusForm = ({ setAllStatus, setDisplayAddForm }) => {
    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [statusData, setStatusData] = useState({
        statusName: "",
        color: ""
    });

    const addStatus = () => {
        setIsSubmitting(true)
        if (statusData.statusName === "") {
            setApiErr("Veullez spécifier le statut.");
            return
        }
        InvoiceAPI.addStatus(statusData).then((response) => {
            setAllStatus(response.data)
            setDisplayAddForm(false)
            setIsSubmitting(false)
        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)
            });
    };

    return (
        <div>
            <div className="modal modal-add">
                <div className="close-icon" onClick={() => setDisplayAddForm(false)}><CloseOutlinedIcon /></div>
                <label>Statut</label>
                <div>
                    <input type="text" onChange={(e) => setStatusData({ ...statusData, statusName: e.target.value })} value={statusData.statusName} />
                </div>
                <div className="form-input">
                    <input type="color" onChange={(e) => setStatusData({ ...statusData, color: e.target.value })} value={statusData.color} />
                </div>

                {apiErr && (
                    <div className="alert"><ErrorOutlineOutlinedIcon />{apiErr}</div>
                )}
                <button disabled={isSubmitting} className="btn btn-primary create-item-btn" onClick={() => { addStatus() }}>{(isSubmitting && ("Ajout...")) || ("Ajouter")}</button>

            </div>
        </div>
    );
};

export default AddStatusForm;