import React, { useState } from "react";
import { CompanyAPI } from "./../../../apis/CompanyAPI";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';



const AddCompanyForm = ({ setDisplayAddForm, setAllCompanies }) => {

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [companyData, setcompanyData] = useState({
        companyName: "",
        isSchool: false
    });

    const addCompany = () => {
        setIsSubmitting(true)

        if (companyData.companyName === "") {
            setApiErr("Veullez spécifier le nom d'entreprise.");
            return
        }
        CompanyAPI.addCompany(companyData).then((response) => {
            setAllCompanies(response.data)
            setDisplayAddForm(false)
            setIsSubmitting(false)

        })
            .catch((err) => {
                setApiErr("Une erreur est survenue");
                setIsSubmitting(false)

            });
    };

  

    return (
        <div className="modal modal-add">
            <div className="close-icon" onClick={() => setDisplayAddForm(false)}><CloseOutlinedIcon /></div>
            <label>Entreprise</label>
            <div>
                <input type="text" onChange={(e) => setcompanyData({ ...companyData, companyName: e.target.value })} value={companyData.companyName} />
            </div>

            <div className="checkbox">
                <label>S'agit-il d'une école ?</label>
                <input type="checkbox" onChange={(e) => setcompanyData({ ...companyData, isSchool: e.target.checked })} checked={companyData.isSchool} />
            </div>
            {apiErr && (
                <div className="alert"><ErrorOutlineOutlinedIcon />{apiErr}</div>
            )}
            <button disabled={isSubmitting} className="btn btn-primary create-item-btn" onClick={() => { addCompany() }}>{(isSubmitting && ("Ajout...")) || ("Ajouter")}</button>

        </div>
    );
};

export default AddCompanyForm;