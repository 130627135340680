import React, {useState} from "react";
import { UserAPI } from "../../apis/UserAPI";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import logo from './../../assets/images/logo.png'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver} from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const yupSchema = yup.object({
        username: yup.string().required("Veuillez remplir ce champ."),
        password: yup.string().required("Veuillez remplir ce champ.")
    });

    const  { register, handleSubmit, getValues, formState:{errors, isSubmitting }, reset, setError, clearErrors } = useForm({
        defaultValues : {
            "username": "",
            "password": ""
        },
        resolver: yupResolver(yupSchema),
        mode: "onSubmit"
    });

    const submit =  async () => {
        await UserAPI.loginCheck(getValues()).then(
            function (response) {
            
                    reset();
                    clearErrors();
                    localStorage.setItem('token', response.data.token)
                    navigate ("/tickets");
                
            }
        ).catch(function (error) {
           
            if (error.response.status === 401  && error.response.data.message==="Too many failed login attempts, please try again in 5 minutes.") {    
                setError('globalError', { type: 'global', message: "Trop de tentatives échouées. Veuillez réessayer dans 5 minutes." });
            }  else if (error.response.status === 401) {
                setError('globalError', { type: 'global', message: "Vos identifiants sont invalides" });
            } else {
                setError('globalError', { type: 'global', message: "Une erreur est survenue" });
            }

        });
    }
    
    return (
        <div className="loginForm-container">
            <form onSubmit={handleSubmit(submit)}>
                <div className="logo-container--mb"><img src={logo} alt="Logo"/></div>
                <h1>Identifiez-vous</h1>
                <div className="form-inputs">
                    <div className="input-container">
                        <span className="material-icons"><PersonOutlineOutlinedIcon/></span>  
                        <input {...register("username")} type="email" placeholder="email@gmail.com"  autoFocus/>
                        {errors?.username && (
                            <div className="alert"><ErrorOutlineOutlinedIcon/><p>{errors.username.message}</p></div>
                        )}
                    </div>
                        
                    <div className="input-container">
                        <span className="material-icons"><LockOutlinedIcon/></span>
                        <div className="password-input">
                            <input {...register("password")} type={showPassword ? "text" : "password"}  placeholder="*************" />
                            <div className="show-icon" onClick={() => setShowPassword(!showPassword)}><RemoveRedEyeOutlinedIcon/></div>
                        </div>
                        {errors?.password && (
                            <div className="alert"><ErrorOutlineOutlinedIcon/>{errors.password.message}</div>
                        )}
                    </div>
                    {errors?.globalError && (
                        <div className="alert"><ErrorOutlineOutlinedIcon/>{errors.globalError.message}</div>
                    )}
                </div>          
                <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Connexion...")) || ("Se connecter")}</button>
            </form>
        </div>

    );
};

export default Home;