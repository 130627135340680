import React, { useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { InvoiceAPI } from "../../../apis/InvoiceAPI";
import { useNavigate } from "react-router-dom";

const DeleteInvoice = ({ invoice }) => {
    const navigate = useNavigate();
    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [displayModal, setDislayModal] = useState(false)

    const deleteInvoice = async () => {
        setIsSubmitting(true)
        await InvoiceAPI.deleteInvoice(invoice.id).then(
            function () {
                navigate("/factures")
            }
        ).catch(function () {
            setApiErr("Une erreur est survenue")
        });
    }

    return (
        <div>
            <div className="btn btn-delete btn-modal" onClick={() => setDislayModal(true)}>Supprimer la facture</div>
            {
                displayModal && (
                    <div className="modal modal-delete">
                        <div className="close-icon" onClick={() => setDislayModal(false)}><CloseOutlinedIcon /></div>
                        <Player
                            autoplay
                            loop
                            speed="0.5"
                            src="https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                        </Player>
                        <p>Supprimer cette facture ?</p>
                        <button onClick={deleteInvoice} disabled={isSubmitting} className="btn btn-delete"> {(isSubmitting && ("Suppression...")) || ("Supprimer")}</button>
                    </div>
                )
            }

        </div>
    );
};

export default DeleteInvoice;