import {axiosPrivateInstance} from './axiosConfig'

export const TicketAPI ={

    getAllTickets: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/tickets`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getTicketById: function(id) {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/ticket/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    deleteTicket: function(id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/ticket/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    updateTicket: function(ticket) {
        return axiosPrivateInstance.request({
            method: "PUT",
            url: `/ticket/edit/${ticket.id}`,
            data: ticket,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
            }
        });
    },

    addTicket: function(ticket) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/ticket/add`,
            data: ticket,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',
            }
        });
    },

    addMessage: function(message) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/message/add`,
            data: message,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`,
                'content-type': 'multipart/form-data',
            }
        });
    },

}

export default TicketAPI