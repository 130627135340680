import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyAPI } from "./../../../apis/CompanyAPI";
import isLoggedIn from "./../../../utils/isLoggedIn";
import userContext from "./../../../context/userContext";
import CompanyItem from "./CompanyItem"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCompanyForm from "./AddCompanyForm";

const AllCompanies = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [allCompanies, setAllCompanies] = useState();
    const [displayAddForm, setDisplayAddForm] = useState(false);


    const getCompanies = () => {
        CompanyAPI.getAllCompanies().then((response) => {
            setIsLoading(false)
            setAllCompanies(response.data)
        })
            .catch(() => {
                setIsLoading(false)
                setApiErr("Une erreur est survenue")
            });
    };

    useEffect(
        () => {
            if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");
            document.title = "Devolie - Les entreprises"
            if (!isLoggedIn()) {
                navigate("/");
            } else {
                getCompanies();
            }
        },
        []
    );

    return (
        <>
            <div>
                <div className="header-with-cta">
                    <h2>Les entreprises</h2>
                    <button onClick={() => setDisplayAddForm(true)} className="btn btn-primary create-item-btn"><AddOutlinedIcon />Ajouter</button>
                </div>
                <hr />
                {isLoading && (
                    <p className="loading">Chargement...</p>
                )}
                {apiErr && (
                    <p className="loading">Une erreur est survenue.</p>
                )}

                {!isLoading && allCompanies && allCompanies.length > 1 && (
                    <ul>
                        {allCompanies.map(company => <CompanyItem company={company} setAllCompanies={setAllCompanies} />)}
                    </ul>
                )}

                {!isLoading && allCompanies && !Array.isArray(allCompanies) && (
                    <ul>
                        <CompanyItem company={allCompanies} setAllCompanies={setAllCompanies} />
                    </ul>
                )}

            </div>
            {
                displayAddForm && <AddCompanyForm setDisplayAddForm={setDisplayAddForm} setAllCompanies={setAllCompanies} />
            }

        </>
    );
};

export default AllCompanies;