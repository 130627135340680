import React, {useEffect, useState} from "react";
import { Player } from '@lottiefiles/react-lottie-player';

const SuccessModal = ({message}) => {
    const [ modalClassName, setModalClassname ]= useState("modal-disappear");

    useEffect(() => {
        setTimeout(() => {
            setModalClassname("")
        }, 1000); 
    
        setTimeout(() => {
            setModalClassname("modal-disappear")
        }, 6000); 
        
    }, []);

    return (
        <div className={` alert-success ${modalClassName}`}>
           <Player
                autoplay
                keepLastFrame
                src="https://assets8.lottiefiles.com/packages/lf20_o3bfunhr.json"
                style={{ height: '120px', width: '120px' }}
            >
            </Player>
            <p>{message}</p>
        </div>

    );
};

export default SuccessModal;