import './editInvoice.scss'
import React, { useContext, useEffect, useState, } from "react";
import userContext from "../../../context/userContext";
import isLoggedIn from "./../../../utils/isLoggedIn";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CollaboratorEdit from "./CollaboratorEdit"
import AdminEdit from "./AdminEdit"
import { InvoiceAPI } from "./../../../apis/InvoiceAPI"
import SuccessModal from "../../../components/successModal";

const EditProfile = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const { userLoggedIn } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true)
    const [invoice, setInvoice] = useState()
    const [apiErr, setApiErr] = useState()
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false)

    const getInvoiceById = () => {
        InvoiceAPI.getInvoiceById(id).then((response) => {
            setInvoice(response.data);
            setIsLoading(false)
        })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate("/factures");
                } else {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                }
            });
    };

    useEffect(() => {
        document.title = "Devolie - Modifier la facture";
        if (!isLoggedIn() && userLoggedIn.roles[0] === "ROLE_CLIENT") {
            navigate("/");
        } else {
            getInvoiceById()
        }
    }, []);

    return (
        <>
            {displaySuccessModal && (<SuccessModal message="La facture a bien été modifiée" />)}
            <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>
            <h1>Modifier la facture</h1>
            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && !invoice && (
                <p className="api-err">Une erreur est survenue.</p>
            )}

            {userLoggedIn.roles[0] === "ROLE_COLLABORATOR" && invoice && <CollaboratorEdit invoice={invoice} setInvoice={setInvoice}  setDisplaySuccessModal={setDisplaySuccessModal}/>}
            {userLoggedIn.roles[0] === "ROLE_ADMIN" && invoice && <AdminEdit invoice={invoice} setInvoice={setInvoice}  setDisplaySuccessModal={setDisplaySuccessModal} />}

        </>

    );
};

export default EditProfile;