import React, { useState, useEffect } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { StatusAPI } from "../../../apis/StatusAPI";
import { PriorityAPI } from "../../../apis/PriorityAPI";
import { UserAPI } from "../../../apis/UserAPI";
import { TicketAPI } from "../../../apis/TicketAPI";
import SuccessModal from "../../../components/successModal";


const Updateticket = ({ ticket, setTicket }) => {
    const actualTicketStatus = {
        id: ticket.id,
        assigned: ticket.assigned ? ticket.assigned.id : "",
        category: ticket.category? ticket.category.id : "",
        priority: ticket.priority? ticket.priority.id : "",
        status: ticket.status ? ticket.status.id : "",
    }

    const [apiErr, setApiErr] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [apiData, setApiData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [displayModal, setDisplayModal] = useState(false)
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
    const [newTicketData, setNewTicketData] = useState(actualTicketStatus)

    const updateTicket = () => {
        setApiErr("");
        setIsSubmitting(true)
        TicketAPI.updateTicket(newTicketData).then((response) => {
        setTicket(response.data)
        setDisplayModal(false);
        setIsSubmitting(false)
        setDisplaySuccessModal(true)

        setTimeout(() => {
            setDisplaySuccessModal(false)
        }, 9000); 
    })
        .catch((err) => {
            setIsSubmitting(false)
            setApiErr("Une erreur est survenue");
        });
    }; 

    useEffect(() => {
        Promise.all([CategoryAPI.getAllCategories(), StatusAPI.getAllStatus(), PriorityAPI.getAllPriorities(), UserAPI.getAssignableUsers()])
            .then(function (results) {
                setApiData({ categories: results[0].data, status: results[1].data, priorities: results[2].data, assignableUsers: results[3].data })
                setIsLoading(false);
                setApiErr("");
            }).catch(function () {
                setApiErr("Une erreur est survenue");
            })

    }, []);

    return (
        <div>
            {displaySuccessModal && (<SuccessModal message="Le ticket a bien été modifié"/>)}
            <div className="btn btn-primary btn-edit btn-modal" onClick={() => setDisplayModal(true)}>Modifier le ticket</div>

            {
                displayModal && (
                    <div className="modal modal-edit">
                        <div className="close-icon" onClick={() => setDisplayModal(false)}><CloseOutlinedIcon /></div>
                        {isLoading && (<p className="loading">Chargement...</p>)}
                        {apiErr && (<p>{apiErr}</p>)}
                        {
                            !isLoading && (
                                <div>
                                    <div className="form-edit">
                                        <div className="select-input">
                                            <div className="form-input">
                                                <label >Assigné</label>
                                                <select onChange={(e) => { setNewTicketData({ ...newTicketData, assigned: Number(e.target.value) }); }} value={newTicketData.assigned}>
                                                    {!actualTicketStatus.assigned && (<option value="" disabled>Choisir</option>)}
                                                    {
                                                        apiData.assignableUsers && Object.keys(apiData.assignableUsers).map((key, i) => <option value={apiData.assignableUsers[key].id} key={apiData.assignableUsers[key].firstname}>{apiData.assignableUsers[key].firstname}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="form-input">
                                                <label>Catégorie</label>
                                                <select onChange={(e) => { setNewTicketData({ ...newTicketData, category: Number(e.target.value) }); }} value={newTicketData.category} >
                                                {!actualTicketStatus.category && (<option value="" disabled>Choisir</option>)}

                                                    {
                                                        apiData.categories && Object.keys(apiData.categories).map((key, i) => <option value={apiData.categories[key].id} key={apiData.categories[key].categoryName}>{apiData.categories[key].categoryName}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="form-input">
                                                <label>Priorité</label>
                                                <select onChange={(e) => { setNewTicketData({ ...newTicketData, priority: Number(e.target.value) }); }} value={newTicketData.priority}>
                                                    {!actualTicketStatus.priority && (<option value="" disabled>Choisir</option>)}
                                                    {
                                                        apiData.priorities && Object.keys(apiData.priorities).map((key, i) => <option value={apiData.priorities[key].id} key={apiData.priorities[key].priorityName}>{apiData.priorities[key].priorityName}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="form-input">
                                                <label>Statut</label>
                                                <select onChange={(e) => { setNewTicketData({ ...newTicketData, status: Number(e.target.value) }); }} value={newTicketData.status}>
                                                    {!actualTicketStatus.status && (<option value="" disabled>Choisir</option>)}
                                                    {
                                                        apiData.status && Object.keys(apiData.status).map((key, i) => <option value={apiData.status[key].id} key={apiData.status[key].statusName}>{apiData.status[key].statusName}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <button onClick={updateTicket} disabled={isSubmitting} className="btn btn-primary"> {(isSubmitting && ("Modification...")) || ("Modifier")}</button>
                                </div>
                            )
                        }
                    </div>
                )
            }


        </div>
    );
};

export default Updateticket;