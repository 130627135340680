import { axiosPrivateInstance} from './axiosConfig'

export const CategoryAPI ={

    getAllCategories: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/categories`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    addCategory: function(categoryData) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/category/add`,
            data: categoryData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    editCategory: function(categoryData, id) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/category/edit/${id}`,
            data: categoryData,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    
    deleteCategory: function( id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/category/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

}

export default CategoryAPI