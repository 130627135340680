import './users.scss';
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserAPI } from "../../apis/UserAPI";
import { NavLink } from "react-router-dom";
import isLoggedIn from "../../utils/isLoggedIn";
import ReactPaginate from 'react-paginate';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UserItem from './UserItem';
import userContext from "../../context/userContext";
import { CompanyAPI } from "../../apis/CompanyAPI";


const AllUsers = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useContext(userContext);
    const [filteredUsers, setFilteredUsers] = useState();
    const [allUsers, setAllUsers] = useState();
    const [isLoading, setIsLoading] = useState(true)
    const [apiErr, setApiErr] = useState()
    const [searchTerm, setSearchTerm] = useState("");
    const [searchRole, setSearchRole] = useState("")
    const [remountComponent, setRemountComponent] = useState(0);
    const [companies, setCompanies] = useState();
    const [searchCompany, setSearchCompany] = useState("");

    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [usersPaginated, setUsersPaginated] = useState(0);
    const itemsPerPage = 7;
    const endOffset = pageOffset + itemsPerPage;

    const paginateData = (data) => data.slice(pageOffset, endOffset);

    const searchCheck = (data, search) => data.toLowerCase().includes(search.toLowerCase());

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredUsers.length;
        setUsersPaginated(filteredUsers.slice(newOffset, newOffset + itemsPerPage));
    };



    const getData = () => {
        Promise.all([UserAPI.getAllUsers(), CompanyAPI.getAllCompanies()])
            .then(function (results) {
                setFilteredUsers(results[0].data);
                setAllUsers(results[0].data);
                setPageCount(Math.ceil(results[0].data.length / itemsPerPage));
                setUsersPaginated(paginateData(results[0].data));
                setIsLoading(false)
                setCompanies(results[1].data)
                setIsLoading(false)

            }).catch(function (err) {
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate("/profil");
                } else {
                    setIsLoading(false)
                    setApiErr("Une erreur est survenue")
                }

            });
    }

    const filterUsers = () => {
        let results = allUsers;
        if (searchTerm) {
            results = results.filter(user => searchCheck(user.lastname, searchTerm) || searchCheck(user.firstname, searchTerm));
        }
        if (searchRole) {
            results = results.filter(user => searchCheck(user.roles[0], searchRole));
        }
        if (searchCompany) {
            results = results.filter(user => user.company !== null);
            results = results.filter(user => searchCheck(user.company.companyName, searchCompany));
        }

        setFilteredUsers(results);
        setPageCount(Math.ceil(results.length / itemsPerPage));
        setUsersPaginated(results.slice(0, 0 + itemsPerPage));
        setPageOffset(0);
        setRemountComponent(Math.random());
        setSearchTerm('');
        setSearchRole('');
        setSearchCompany('');
    }

    useEffect(
        () => {
            if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");
            document.title = "Devolie - Les utilisateurs"
            if (!isLoggedIn()) {
                navigate("/");
            } else {
                getData();
            }
        },
        []
    );

    return (
        <div>

            <div className="header-with-cta">
                <h1>Les utilisateurs</h1>
                <NavLink to="/utilisateur/ajouter">
                    <button className="btn btn-primary create-item-btn"><AddOutlinedIcon />Ajouter</button>
                </NavLink>
            </div>

            {isLoading && (
                <p className="loading">Chargement...</p>
            )}
            {apiErr && (
                <p className="loading">Une erreur est survenue.</p>
            )}

            {!isLoading && allUsers && allUsers.length > 0 && (
                <>
                    <div className="search-container" >

                        <input name="searchTerm" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder=" Prénom, nom" />
                        <select value={searchCompany} onChange={(e) => { setSearchCompany(e.target.value) }}>
                            <option value="">Entreprise</option>
                            {
                                companies && Object.keys(companies).map((key, i) => <option value={companies[i].companyName} key={companies[i].companyName}>{companies[i].companyName}</option>)
                            }
                        </select>
                        <select name="role" value={searchRole} onChange={(e) => { setSearchRole(e.target.value) }}>
                            <option value="">Rôle</option>
                            <option value="client">Client</option>
                            <option value="admin">Admin</option>
                            <option value="collaborator">Collaborateur</option>
                        </select>

                        <button className="search-btn" onClick={filterUsers}><span className="btn-content"><SearchOutlinedIcon />Rechercher</span></button>

                    </div>
                    {usersPaginated.length > 0 && (
                        <div className="table-container">
                            <table className="list-container ">
                                <thead className="list-container--header">
                                    <tr>
                                        <th className="avatar">Avatar</th>
                                        <th className="name">
                                            <p>Prénom Nom</p>
                                        </th>
                                        <th className="phone">Téléphone</th>
                                        <th className="role">Rôle</th>
                                        <th className="company">Entreprise</th>
                                        <th className="website">Site</th>
                                        <th className="email">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        usersPaginated.map(user => <UserItem user={user} key={user.id} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!usersPaginated.length > 0 && (<p className="no-item-found">Pas d'utilisateurs trouvés.</p>)}
                    <div className="pagination" key={remountComponent}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            forcePage={pageOffset}
                        />
                    </div>
                </>

            )}
            {!isLoading && allUsers && allUsers.length === 0 && (
                <p>Pas d'utilisateurs disponibles.</p>
            )}
        </div>

    );
};

export default AllUsers;