const displayIndexOrNot = (data, status) => {
    let dataWithNoStatus = data.filter(data => data.status === null );
    let unresolvedData = data.filter(data => data.status !== null );
    unresolvedData = unresolvedData.filter(ticket => ticket.status.statusName !== status)
    if(dataWithNoStatus.length === 0 && unresolvedData.length === 0) {
        return false;
    };

    return true
}

export { displayIndexOrNot }