import {axiosInstance, axiosPrivateInstance} from './axiosConfig'

export const UserAPI ={
    loginCheck: function(credentials) {
        return axiosInstance.request({
            method: "POST",
            url: `/login_check`,
            data: credentials
        });
    },

    logout: function() {
        return axiosInstance.request({
            method: "POST",
            url: `/logout`,
        });
    },

    refreshToken: function() {
        return axiosInstance.request({
            method: "POST",
            url: `/token/refresh`,
         
        });
    },

    getUser: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/profile`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    editUser: function(user) {
        return axiosPrivateInstance.request({
            method: "PUT",
            url: `/user/edit/${user.id}`,
            data: user,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    addUser: function(user) {
        return axiosPrivateInstance.request({
            method: "POST",
            url: `/user/add`,
            data: user,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },


    getUserById: function(id) {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/user/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getAllUsers: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/users`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    deleteUser: function(id) {
        return axiosPrivateInstance.request({
            method: "DELETE",
            url: `/user/delete/${id}`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    getAssignableUsers: function() {
        return axiosPrivateInstance.request({
            method: "GET",
            url: `/assignable/users`,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    editPassword: function(passwords) {
        return axiosPrivateInstance.request({
            method: "PUT",
            url: `/profile/edit-password`,
            data: passwords,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    },

    editAvatar: function (userId, avatar) {
        return axiosPrivateInstance.request({
            method: "PUT",
            url: `/profile/edit-avatar/${userId}`,
            data: avatar,
            headers: {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        });
    }
}

export default UserAPI