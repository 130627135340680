import React, { useContext, useEffect }from "react";
import PasswordForm from './PasswordForm';
import AvatarForm from "./../../../components/AvatarForm";

import userContext from "../../../context/userContext";

const EditProfile = () => {
    const {userLoggedIn} = useContext(userContext);

    useEffect(() => {
        document.title = "Devolie - Modifier mon profil"
    }, []);
    return (
       <>
            <h1>Modifier mon profil</h1>
            <AvatarForm userData={userLoggedIn}/>
            <PasswordForm/>
       </>

    );
};

export default EditProfile;