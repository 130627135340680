import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAPI } from "../../../apis/UserAPI";
import { CompanyAPI } from "../../../apis/CompanyAPI";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SuccessModal from "../../../components/successModal";
import AvatarForm from "../../../components/AvatarForm";
import userContext from "./../../../context/userContext";
import DeleteUser from "./DeleteUser";
import roles from "./../../../utils/roles"

const EditUser = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useContext(userContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState()
  const [apiErr, setApiErr] = useState()
  const [companies, setCompanies] = useState()

  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);

  const yupSchema = yup.object({
    firstname: yup.string().required("Veuillez remplir ce champ."),
    lastname: yup.string().required("Veuillez remplir ce champ."),
    email: yup.string().email("Veuillez renseigner un email valide").required("Veuillez remplir ce champ."),
    website: yup.string().required("Veuillez remplir ce champ."),
  });

  const { register, handleSubmit, formState: { errors, isSubmitting }, setError, clearErrors } = useForm({
    resolver: yupResolver(yupSchema),
    mode: "onSubmit"
  });

  const submit = async () => {
    await UserAPI.editUser(user).then(
      function (response) {
        setDisplaySuccessModal(true);
        setTimeout(() => {
          setDisplaySuccessModal(false);
        }, 9000);

      }
    ).catch(function (error) {
      setError('globalError', { type: 'global', message: "Une erreur est survenue" });
    });
  }


  useEffect(
    () => {
      if (userLoggedIn.roles[0] !== "ROLE_ADMIN") navigate("/profil");
      document.title = "Devolie - Modifier un utilisateur";

      Promise.all([CompanyAPI.getAllCompanies(), UserAPI.getUserById(id)])
        .then(function (results) {
          setCompanies(results[0].data)
          setUser(results[1].data);
          setIsLoading(false);
          setApiErr("");
        }).catch(function () {
          setApiErr("Une erreur est survenue");
          setIsLoading(false);
        })

    },
    []
  );

  return (
    <main>
      {
        displaySuccessModal && (<SuccessModal message={"L'utilisateur a bien été modifié"} />)
      }
      <p className="go-back" onClick={() => navigate(-1)}><ArrowBackIosNewOutlinedIcon />Retour</p>

      {isLoading && (
        <p className="loading">Chargement...</p>
      )}

      {apiErr && (
        <p className="loading">Une erreur est survenue.</p>
      )}

      {!isLoading && user && companies && (
        <form onSubmit={handleSubmit(submit)}>

          <AvatarForm userData={user} />

          <div className="select-container form-input">
            <label>Rôle*</label>
            <select name="roles" onChange={(e) => { setUser({ ...user, roles: [e.target.value] }); }} value={user.roles[0]}>
              {
                Object.keys(roles).map((key, i) => <option value={roles[key]}>{key}</option>)
              }
            </select>
          </div>

          <div>
            <div className="form-input">
              <label>Nom*</label>
              <input {...register("lastname")} type="text" onChange={(e) => setUser({ ...user, lastname: e.target.value })} value={user.lastname} />
            </div>
            {errors?.lastname && (
              <div className="alert"><ErrorOutlineOutlinedIcon />{errors.lastname.message}</div>
            )}
          </div>


          <div>
            <div className="form-input">
              <label>Prénom*</label>
              <input {...register("firstname")} type="text" onChange={(e) => setUser({ ...user, firstname: e.target.value })} value={user.firstname} />
            </div>
            {errors?.firstname && (
              <div className="alert"><ErrorOutlineOutlinedIcon />{errors.firstname.message}</div>
            )}
          </div>

          <div>
            <div className="form-input">
              <label>Email*</label>
              <input {...register("email")} type="text" onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} />
            </div>
            {errors?.email && (
              <div className="alert"><ErrorOutlineOutlinedIcon />{errors.email.message}</div>
            )}
          </div>

          <div className="select-container form-input">
            <label>Entreprise*</label>
            <select onChange={(e) => { setUser({ ...user, company: { id: Number(e.target.value) } }); }} value={user.company? user.company.id : ""}>
              {
                companies.map(company => <option value={company.id} key={company.id + company.companyName}>{company.companyName}</option>)
              }
            </select>
          </div>

          <div>
            <div className="form-input">
              <label>Site*</label>
              <input {...register("website")} type="text" onChange={(e) => setUser({ ...user, website: e.target.value })} value={user.website} />
            </div>
            {errors?.website && (
              <div className="alert"><ErrorOutlineOutlinedIcon />{errors.website.message}</div>
            )}
          </div>

          <div>
            <div className="form-input">
              <label>Téléphone</label>
              <input {...register("phone")} type="text" onChange={(e) => setUser({ ...user, phone: e.target.value })} value={user.phone} placeholder='0660504030' />
            </div>
            {errors?.phone && (
              <div className="alert"><ErrorOutlineOutlinedIcon />{errors.phone.message}</div>
            )}
          </div>


          {errors?.globalError && (
            <div className="alert"><ErrorOutlineOutlinedIcon />{errors.globalError.message}</div>
          )}
          <div className="btn-container btn-container--left">
            <button disabled={isSubmitting} className="btn btn-primary btn-login" type="submit"> {(isSubmitting && ("Modification...")) || ("Sauvegarder")}</button>
            <DeleteUser userId={user.id} />
          </div>
        </form>
      )

      }
    </main>

  );
};

export default EditUser;